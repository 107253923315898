import { ListItemButton, ListItemText } from '@mui/material';

interface IQuickConnectListItemProps {
  endpoint: connect.Endpoint;
  flat?: boolean;
  setSelectedQc: (id: string) => void;
  selectedQc: string;
}
export const QuickConnectListItem = ({
  endpoint,
  flat = false,
  setSelectedQc,
  selectedQc,
}: IQuickConnectListItemProps) => {
  const handleClick = () => {
    if (selectedQc === endpoint.endpointId) {
      setSelectedQc('');
    } else {
      setSelectedQc(endpoint.endpointId);
    }
  };

  return (
    <ListItemButton
      selected={selectedQc === endpoint.endpointId}
      onClick={handleClick}
    >
      <ListItemText
        inset={!flat}
        primary={endpoint.name}
        primaryTypographyProps={{
          noWrap: endpoint.name.split(' ').length > 1 ? false : true,
        }}
      />
    </ListItemButton>
  );
};
