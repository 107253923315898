export const getConfig = () => {
  const env = import.meta.env;
  const config = {
    OAUTH_RESPONSE_TYPE: env.VITE_OAUTH_RESPONSE_TYPE,
    OAUTH_SCOPES: env.VITE_OAUTH_SCOPES,
    REGION: env.VITE_REGION,
    USER_POOL_ID: env.VITE_USER_POOL_ID,
    USER_POOL_CLIENT_ID: env.VITE_USER_POOL_CLIENT_ID,
    DOMAIN_URL: env.VITE_DOMAIN_URL,
    COGNITO_SAML_IDP_NAME: env.VITE_COGNITO_SAML_IDP_NAME,
    CCP_URL: env.VITE_CCP_URL,
    CCP_SSO_URL: env.VITE_CCP_SSO_URL,
    SERVER_API_BASE_URL: env.VITE_SERVER_API_BASE_URL,
    CONNECT_INSTANCE_DOMAIN: env.VITE_CONNECT_INSTANCE_DOMAIN,
    ENVIRONMENT: env.VITE_ENVIRONMENT,
    RECORD_ON_DEMAND_SG_ID: env.VITE_RECORD_ON_DEMAND_SG_ID,
    USER_SG_ATTRIBUTE_NAME: env.VITE_USER_SG_ATTRIBUTE_NAME,
  };
  return config;
};
