import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuthContext } from './AuthProvider';
import { Config } from '../types';
import { logger } from '../utilities/logger';
import { useAppStateContext } from './AppStateProvider';
import { useApiContext } from './ApiProvider';

interface IConfigContext {
  config: Config | null;
  configInitialLoading: boolean;
}

const ConfigContext = createContext<IConfigContext | null>(null);

export function useConfigContext() {
  const state = useContext(ConfigContext);

  if (!state) {
    throw new Error('useConfigContext must be used within ConfigProvider');
  }

  return state;
}

export function ConfigProvider({ children }: React.PropsWithChildren) {
  const log = logger();
  const [config, setConfig] = useState<Config | null>(null);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [count, setCount] = useState<number>(0);
  const { authStatus } = useAuthContext();
  const { tokens } = useAuthContext();
  const { setSnackbar } = useAppStateContext();
  const { getRequest } = useApiContext();

  // Simple interval without dependencies triggers useEffect to get/refresh configuration
  // TODO - find better way to do this
  useEffect(() => {
    const id = setInterval(() => {
      setCount((c) => c + 1);
    }, 60000);
    return () => clearInterval(id);
  }, []);

  // TODO: compare data retrieved with config in state, only update if needed
  const getConfig = async () => {
    try {
      getRequest('/api/config')
        .then((data) => {
          if (data) {
            setConfig({ ...data.data });
            if (initialLoading) {
              setInitialLoading(false);
            }
          }
        })
        .catch((error) => {
          throw error;
        });
    } catch (error) {
      log.error('Error retrieving config from server', error);
      setSnackbar({
        message: 'Error retrieving agent desktop config from server',
        timeout: 5000,
        open: true,
        severity: 'error',
      });
      setInitialLoading(false);
    }
  };

  // Performs initial getConfig call on login/init
  useEffect(() => {
    if (initialLoading && tokens.idToken) {
      getConfig();
    }
  }, [tokens.idToken]);

  // Performs subsequet config retrieval using count
  useEffect(() => {
    if (authStatus === 'authenticated' && !initialLoading && tokens.idToken) {
      getConfig();
    }
  }, [authStatus, count]);

  const providerValue = {
    config,
    configInitialLoading: initialLoading,
  };

  return (
    <ConfigContext.Provider value={providerValue}>
      {children}
    </ConfigContext.Provider>
  );
}
