import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useContactContext } from './ContactProvider';
import { logger } from '../utilities/logger';

interface IQuickConnectContext {
  agentEndpoints: connect.GetEndpointsResult | null;
}

const QuickConnectContext = createContext<IQuickConnectContext | null>(null);

export function useQuickConnectionContext() {
  const state = useContext(QuickConnectContext);
  if (!state) {
    throw new Error(
      'useQuickConnectContext must be used within QuickConnectProvider'
    );
  }
  return state;
}

export function QuickConnectProvider({ children }: React.PropsWithChildren) {
  const log = logger();

  // Provider Context
  const { agent } = useContactContext();

  // Provider States
  const [agentEndpoints, setAgentEndpoints] =
    useState<connect.GetEndpointsResult | null>(null);

  const endpointRef = useRef<connect.GetEndpointsResult | null>(null);
  endpointRef.current = agentEndpoints;

  const compareEndpointArrays = (
    array1: connect.Endpoint[],
    array2: connect.Endpoint[]
  ): boolean => {
    // Check length of both arrays. If length is not equal, return false
    if (array1.length !== array2.length) return false;

    // Loop through array1, find equivalent object, and compare. If any discrepencies, return false
    for (const array1Endpoint of array1) {
      const found = array2.find(
        (array2Endpoint) =>
          array2Endpoint.endpointARN === array1Endpoint.endpointARN
      );
      if (!found) return false;
      if (array1Endpoint.agentLogin !== found.agentLogin) return false;
      if (array1Endpoint.endpointARN !== found.endpointARN) return false;
      if (array1Endpoint.endpointId !== found.endpointId) return false;
      if (array1Endpoint.name !== found.name) return false;
      if (array1Endpoint.phoneNumber !== found.phoneNumber) return false;
      if (array1Endpoint.queue !== found.queue) return false;
      if (array1Endpoint.type !== found.type) return false;
    }

    // If lengths are equal and all properties are equal, the endpoints are equal. Return true
    return true;
  };

  // Update agent queues and endpoints when agent is updated and subscribe method to onRefresh
  useEffect(() => {
    if (agent) {
      const qs = agent.getAllQueueARNs();
      const rp = agent.getRoutingProfile();
      const outboundQueueArn = rp.defaultOutboundQueue.queueARN;
      qs.push(outboundQueueArn);
      agent.getEndpoints(qs, {
        success: (data) => setAgentEndpoints(data),
        failure: (data) => log.error('Error retrieving endpoints', data),
      });
      agent.onRefresh((agent) => {
        const qs = agent.getAllQueueARNs();
        const rp = agent.getRoutingProfile();
        const outboundQueueArn = rp.defaultOutboundQueue.queueARN;
        qs.push(outboundQueueArn);
        agent.getEndpoints(qs, {
          success: (data) => {
            setAgentEndpoints(data);
          },
          failure: (data) => log.error('Error retrieving endpoints', data),
        });
      });
    }
  }, [agent]);

  const providerValue = {
    agentEndpoints,
  };

  return (
    <QuickConnectContext.Provider value={providerValue}>
      {children}
    </QuickConnectContext.Provider>
  );
}
