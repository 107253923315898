import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { App } from './App.tsx';
import { Amplify } from 'aws-amplify';
import { Authenticator } from '@aws-amplify/ui-react';
import { AuthProvider } from './providers/AuthProvider.tsx';
import { ConfigProvider } from './providers/ConfigProvider.tsx';
import { getConfig } from './config.ts';
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './index.css';
import { ContactProvider } from './providers/ContactProvider.tsx';
import { AppStateProvider } from './providers/AppStateProvider.tsx';
import { ApiProvider } from './providers/ApiProvider.tsx';
import './i18n';

const config = getConfig();

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: config.USER_POOL_ID,
      userPoolClientId: config.USER_POOL_CLIENT_ID,
      loginWith: {
        oauth: {
          domain: config.DOMAIN_URL,
          scopes: JSON.parse(config.OAUTH_SCOPES),
          redirectSignIn: [
            'http://localhost:3000',
            `${window.location.protocol}//${window.location.host}`,
          ],
          redirectSignOut: [
            'http://localhost:3000/logout',
            `${window.location.protocol}//${window.location.host}/logout`,
          ],
          responseType: config.OAUTH_RESPONSE_TYPE,
        },
      },
    },
  },
});

const theme = createTheme();

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <AppStateProvider>
          <Authenticator.Provider>
            <BrowserRouter>
              <AuthProvider>
                <ApiProvider>
                  <ConfigProvider>
                    <ContactProvider>
                      <App />
                    </ContactProvider>
                  </ConfigProvider>
                </ApiProvider>
              </AuthProvider>
            </BrowserRouter>
          </Authenticator.Provider>
        </AppStateProvider>
      </CssBaseline>
    </ThemeProvider>
  </React.StrictMode>
);
