interface ITabPanelProps {
  value: number;
  index: number;
  children?: React.ReactNode;
}

export const TabPanel = (props: ITabPanelProps) => {
  const { value, index, children, ...other } = props;

  return (
    <div
      role='tabPanel'
      hidden={value !== index}
      id={`tab-panel-${index}`}
      style={{ height: '100%' }}
      {...other}
    >
      {children}
    </div>
  );
};
