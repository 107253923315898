import { createContext, useContext } from 'react';
import { AxiosResponse } from 'axios';
import { useAuthContext } from './AuthProvider';
import { getApiClient } from '../utilities/api';

interface IApiContext {
  getRequest: (path: string) => Promise<AxiosResponse<any, any>>;
  putRequest: (
    path: string,
    body: { [key: string]: any }
  ) => Promise<AxiosResponse<any, any>>;
  postRequest: (
    path: string,
    body: { [key: string]: any }
  ) => Promise<AxiosResponse<any, any>>;
}

const ApiContext = createContext<IApiContext | null>(null);

export function useApiContext() {
  const state = useContext(ApiContext);

  if (!state) {
    throw new Error('useApiContext must be used within ApiProvider');
  }

  return state;
}

export function ApiProvider({ children }: React.PropsWithChildren) {
  // Provider Contexts
  const { tokens, refreshTokens } = useAuthContext();

  // API Methods
  const getRequest = async (path: string) => {
    return await getApiClient(refreshTokens).get(path, {
      headers: { Authorization: 'Bearer ' + tokens.idToken },
    });
  };

  const putRequest = async (path: string, body: { [key: string]: any }) => {
    return await getApiClient(refreshTokens).put(path, body, {
      headers: { Authorization: 'Bearer ' + tokens.idToken },
    });
  };

  const postRequest = async (path: string, body: { [key: string]: any }) => {
    return await getApiClient(refreshTokens).post(path, body, {
      headers: { Authorization: 'Bearer ' + tokens.idToken },
    });
  };

  const providerValue = {
    getRequest,
    putRequest,
    postRequest,
  };

  return (
    <ApiContext.Provider value={providerValue}>{children}</ApiContext.Provider>
  );
}
