import { NestedQuickConnectListItem } from './NestedQuickConnectListItem';
import { displayQc } from '../utilities';

interface QuickConnectConfig {
  name: string;
  quickConnects: string[];
}

interface IQuickConnectListProps {
  agentEndpoints: connect.GetEndpointsResult;
  selectedContact: connect.Contact | null;
  quickConnectConfig: QuickConnectConfig[] | [];
  setSelectedQc: (id: string) => void;
  selectedQc: string;
}

export const NestedQuickConnectList = ({
  agentEndpoints,
  selectedContact,
  quickConnectConfig = [],
  setSelectedQc,
  selectedQc,
}: IQuickConnectListProps) => {
  /* 
    If quick connect config exists as a non-empty object:
      - Sort agent endpoint objects into categories taking conditions into account
      - Within each category, sort endpoints alphabetically
      - Omit any categories with no entries
      - Render nested list
  */

  // Sort endpoints into categories
  const quickConnectsSorted = {} as { [key: string]: connect.Endpoint[] };

  for (const config of quickConnectConfig) {
    // Create property for category and set value to empty array to contain endpoints
    quickConnectsSorted[config.name] = [];
    for (const endpoint of agentEndpoints.endpoints) {
      // For each agent endpoint, see if it's included in the category list and if it should be conditionally displayed. If so, add the endpoint to the category array
      const endpointArn = endpoint.endpointARN.split('/');
      const endpointId = endpointArn[endpointArn.length - 1];
      if (
        config.quickConnects.includes(endpointId) &&
        displayQc(endpoint, selectedContact)
      ) {
        quickConnectsSorted[config.name].push(endpoint);
      }
    }
    // Sort the category endpoint list alphabetically
    quickConnectsSorted[config.name].sort((a, b) =>
      a.name.localeCompare(b.name)
    );
  }

  // Create an array to hold the nested list items
  const listItems = [];

  // Get a list of category names from the object keys
  const categoryNames = Object.keys(quickConnectsSorted);

  // Loop through the categories and construct each item in the list if the category has items
  for (const name of categoryNames) {
    if (quickConnectsSorted[name].length) {
      listItems.push(
        <NestedQuickConnectListItem
          name={name}
          quickConnects={quickConnectsSorted[name]}
          setSelectedQc={setSelectedQc}
          selectedQc={selectedQc}
          key={name}
        />
      );
    }
  }

  return <>{listItems}</>;
};
