import { FC, useCallback, useMemo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from './pages/Dashboard';
import { Login } from './pages/Login';
import { ProtectedRoute } from './components/ProtectedRoute';
import { AppLayout } from './components/AppLayout';
import { Logout } from './pages/Logout';
import { PageNotFound } from './pages/PageNotFound';

const withLayout = (Component: FC) => (props: any) => {
  const Comp = useCallback(() => <Component {...props} />, [props]);
  return (
    <AppLayout>
      <Comp />
    </AppLayout>
  );
};

export const App = () => {
  const DashboardWithLayout = useMemo(() => withLayout(Dashboard), []);
  const LoginWithLayout = useMemo(() => withLayout(Login), []);
  const LogoutWithLayout = useMemo(() => withLayout(Logout), []);
  const PageNotFoundWithLayout = useMemo(() => withLayout(PageNotFound), []);

  const routes = () => {
    return (
      <Routes>
        <Route path='/'>
          <Route
            index
            element={
              <ProtectedRoute>
                <DashboardWithLayout />
              </ProtectedRoute>
            }
          />
          <Route path='logout' element={<LogoutWithLayout />} />
          <Route path='login' element={<LoginWithLayout />} />
          <Route path='*' element={<PageNotFoundWithLayout />} />
        </Route>
      </Routes>
    );
  };

  return routes();
};
