import React from 'react';
import { useAuthContext } from '../providers/AuthProvider';
import { logger } from '../utilities/logger';
import { jwtDecode } from 'jwt-decode';

interface IProtectedComponentProps {
  children: React.ReactNode;
  requiredSgId: string;
  userSgAttributeName: string;
}

export const ProtectedComponent = ({
  children,
  requiredSgId,
  userSgAttributeName,
}: IProtectedComponentProps) => {
  const log = logger();
  const { tokens } = useAuthContext();
  if (tokens && tokens.idToken) {
    if (!requiredSgId || !userSgAttributeName) {
      log.error(
        'REQUIRED SECURITY GROUP ID OR USER SECURITY GROUP ATTRIBUTE NAME ARE FALSY, UNABLE TO EVALUATE PROTECTED COMPONENT'
      );
      return null;
    }
    const decoded = jwtDecode(tokens.idToken);
    //@ts-expect-error figure out how to use appropriate types here
    if (!decoded[userSgAttributeName]) {
      log.debug(
        'USER TOKEN DOES NOT CONTAIN SECURITY GROUP ATTRIBUTE, UNABLE TO EVALUATE PROTECTED COMPONENT'
      );
      return null;
    }
    //@ts-expect-error figure out how to use appropriate types here
    const sgString = decoded[userSgAttributeName];
    if (sgString.includes(requiredSgId)) {
      return children;
    }
    return null;
  }
  return null;
};
