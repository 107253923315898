import { useState } from 'react';
import { useAppStateContext } from '../providers/AppStateProvider';
import { useAuthContext } from '../providers/AuthProvider';
import { signInWithRedirect } from 'aws-amplify/auth';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { logger } from '../utilities/logger';
import { getConfig } from '../config';
import { Navigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

export const Logout = () => {
  const theme = useTheme();
  const log = logger();
  const { t } = useTranslation();

  const config = getConfig();

  // Component State
  const [loading, setLoading] = useState(false);

  // Component Context
  const { setSnackbar } = useAppStateContext();
  const { authStatus } = useAuthContext();

  const login = async () => {
    try {
      setLoading(true);
      await signInWithRedirect({
        provider: { custom: config.COGNITO_SAML_IDP_NAME },
      });
    } catch (error) {
      log.error('Error signing in: ', error);
      setSnackbar({
        message: t('snackbarLoginError'),
        timeout: 5000,
        open: true,
        severity: 'warning',
      });
      setLoading(false);
    }
  };

  return (
    <>
      {authStatus === 'authenticated' ? (
        <Navigate to='/' replace />
      ) : (
        <Grid
          container
          direction='column'
          justifyContent='center'
          alignItems='center'
          flexGrow={1}
          height='100%'
        >
          <Grid item>
            {authStatus === 'configuring' ? (
              <CircularProgress />
            ) : (
              <>
                <Typography
                  color={theme.palette.info.main}
                  variant='h6'
                  margin='8px'
                  textAlign={'center'}
                >
                  {t('signedOut')}
                </Typography>
                <LoadingButton
                  onClick={() => login()}
                  variant='contained'
                  loading={loading}
                  size={'large'}
                >
                  {t('loginWithSso')}
                </LoadingButton>
              </>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
};
