import axios from 'axios';
import { Tokens } from '../types';
import { getConfig } from '../config';

export const getApiClient = (refreshTokens: () => Promise<Tokens>) => {
  const appConfig = getConfig();
  let apiBaseUrl = '';
  if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
    apiBaseUrl = appConfig.SERVER_API_BASE_URL;
  }
  const axiosClient = axios.create({
    baseURL: apiBaseUrl,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  axiosClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        error?.response?.status === 401 &&
        error?.response?.data?.message === 'The incoming token has expired' &&
        !originalRequest._retry
      ) {
        try {
          originalRequest._retry = true;
          const result = await refreshTokens();
          delete originalRequest.headers.Authorization;
          originalRequest.headers.Authorization = 'Bearer ' + result.idToken;
          return await axiosClient(originalRequest);
        } catch (error) {
          return Promise.reject(error);
        }
      }
      return Promise.reject(error);
    }
  );

  return axiosClient;
};
