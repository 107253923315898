import React from 'react';
import { AccountCircle, Logout, OpenInNewOutlined } from '@mui/icons-material';
import {
  Alert,
  AppBar,
  Snackbar,
  IconButton,
  Menu,
  MenuItem,
  Slide,
  Toolbar,
  Typography,
  Unstable_Grid2 as Grid,
  Divider,
  ListItemIcon,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useAuthContext } from '../providers/AuthProvider';
import { useContactContext } from '../providers/ContactProvider';
import { useAppStateContext } from '../providers/AppStateProvider';
import { signOut } from 'aws-amplify/auth';
import { logger } from '../utilities/logger';
import { useConfigContext } from '../providers/ConfigProvider';
import { useTranslation } from 'react-i18next';
import Logo from '../images/logo.svg';

export const AppLayout = ({ children }: { children: React.ReactNode }) => {
  const log = logger();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { authStatus, setLogout } = useAuthContext();
  const { agentOfflineCcpLogout } = useContactContext();
  const { resetSnackbar, snackbar } = useAppStateContext();
  const { config } = useConfigContext();
  const { t } = useTranslation();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    try {
      handleClose();
      await agentOfflineCcpLogout();
    } catch (error) {
      log.error('Logout Error', error);
    }
  };

  const externalUrlMenuItems = (): JSX.Element[] | null => {
    if (config && config.externalUrlConfig) {
      const menuItems = [] as JSX.Element[];
      for (const urlConfig of config.externalUrlConfig) {
        menuItems.push(
          <MenuItem
            onClick={() => window.open(urlConfig.url)}
            key={urlConfig.url}
          >
            <ListItemIcon>
              <OpenInNewOutlined fontSize='small' />
            </ListItemIcon>
            <Typography noWrap>{urlConfig.label}</Typography>
          </MenuItem>
        );
      }
      if (menuItems.length) {
        menuItems.push(<Divider />);
      }
      return menuItems;
    }
    return null;
  };

  return (
    <>
      <Grid
        container
        direction='column'
        sx={{
          height: '100%',
          backgroundColor: theme.palette.grey[100],
          overflow: 'auto',
          minWidth: '1070px',
        }}
        className='main-layout-grid-container'
        flexWrap={'nowrap'}
      >
        <Grid>
          <Grid
            container
            direction='row'
            justifyContent='center'
            className='top-nav-grid-container'
          >
            <Grid width='100%'>
              <AppBar position='static'>
                <Toolbar>
                  <img
                    src={Logo}
                    alt='Covered California Logo'
                    style={{ height: '60px', padding: '12px' }}
                  />
                  <Typography
                    variant='h6'
                    component='div'
                    sx={{ flexGrow: 1, paddingLeft: '.5rem' }}
                  >
                    {t('agentDesktop')}
                  </Typography>
                  {authStatus !== 'unauthenticated' && (
                    <div>
                      <IconButton
                        size='medium'
                        aria-label='account of current user'
                        aria-controls='menu-appbar'
                        aria-haspopup='true'
                        onClick={handleMenu}
                        color='inherit'
                        sx={{ padding: '4px' }}
                      >
                        <AccountCircle />
                      </IconButton>
                      <Menu
                        id='menu-appbar'
                        anchorEl={anchorEl}
                        transformOrigin={{
                          horizontal: 'right',
                          vertical: 'top',
                        }}
                        anchorOrigin={{
                          horizontal: 'right',
                          vertical: 'bottom',
                        }}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                      >
                        {externalUrlMenuItems()}
                        <MenuItem onClick={() => logout()}>
                          <ListItemIcon>
                            <Logout fontSize='small' />
                          </ListItemIcon>
                          <Typography noWrap>{t('signOut')}</Typography>
                        </MenuItem>
                      </Menu>
                    </div>
                  )}
                </Toolbar>
              </AppBar>
            </Grid>
          </Grid>
        </Grid>
        <Grid flex={1} overflow='auto'>
          {children}
        </Grid>
        <Snackbar
          open={snackbar.open}
          autoHideDuration={snackbar.timeout}
          onClose={resetSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          TransitionComponent={Slide}
        >
          <Alert
            onClose={resetSnackbar}
            severity={snackbar.severity}
            sx={{ width: '100%' }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Grid>
    </>
  );
};
