import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import 'amazon-connect-streams';
import { ViewContactEvent } from '../types';
import { getConfig } from '../config';
import { logger } from '../utilities/logger';
import { useApiContext } from './ApiProvider';
import { useAppStateContext } from './AppStateProvider';
import { AgentExternalConfig } from '../types';
import { useTranslation } from 'react-i18next';
import { Hub } from 'aws-amplify/utils';
import { useAuthContext } from './AuthProvider';
import { signOut } from 'aws-amplify/auth';

type ICcpInitFailedStatus =
  | ''
  | 'iframeRetriesExceeded'
  | 'authFail'
  | 'accessDenied'
  | 'authorizeRetriesExhausted'
  | 'ctiAuthorizeRetriesExhausted';

interface IContactContext {
  getSelectedContactId: () => string;
  ccpLogout: () => Promise<void>;
  ccpInitialized: boolean;
  setCcpInitialized: (state: boolean) => void;
  ccpInitFailed: boolean;
  setCcpInitFailed: (state: boolean) => void;
  ccpInitFailedStatus: ICcpInitFailedStatus;
  setCcpInitFailedStatus: (state: ICcpInitFailedStatus) => void;
  selectedContactId: string;
  setSelectedContactId: (state: string) => void;
  selectedContact: connect.Contact | null;
  setSelectedContact: (state: connect.Contact | null) => void;
  updateAgent: (agent: connect.Agent) => void;
  agent: connect.Agent | null;
  agentExternalConfig: AgentExternalConfig | null;
  setAgentExternalConfig: (config: AgentExternalConfig) => void;
  agentOfflineCcpLogout: () => Promise<void>;
}

const ContactContext = createContext<IContactContext | null>(null);

export function useContactContext() {
  const state = useContext(ContactContext);

  if (!state) {
    throw new Error('useContactContext must be used within ContactProvider');
  }

  return state;
}

export function ContactProvider({ children }: React.PropsWithChildren) {
  const log = logger();

  // Provider states
  const [ccpInitialized, setCcpInitialized] = useState<boolean>(false);
  const [ccpInitFailed, setCcpInitFailed] = useState<boolean>(false);
  const [ccpInitFailedStatus, setCcpInitFailedStatus] =
    useState<ICcpInitFailedStatus>('');
  const [selectedContactId, setSelectedContactId] = useState<string>('');
  const [selectedContact, setSelectedContact] =
    useState<connect.Contact | null>(null);
  const [agent, setAgent] = useState<connect.Agent | null>(null);
  const [agentExternalConfig, setAgentExternalConfig] =
    useState<AgentExternalConfig | null>(null);

  // Provider Contexts
  const { getRequest } = useApiContext();
  const { setSnackbar } = useAppStateContext();
  const { authStatus, setLogout } = useAuthContext();
  const { t } = useTranslation();

  const config = getConfig();

  const updateAgent = (agent: connect.Agent) => {
    setAgent(agent);
  };

  // Set up methods to update selected contact when view changes in CCP
  useEffect(() => {
    if (ccpInitialized) {
      connect.core.onViewContact((event: ViewContactEvent) => {
        if (event && event.contactId) {
          setSelectedContactId(event.contactId);
          connect.agent((agent) => {
            const contactApi = agent
              .getContacts()
              .find((c) => c.contactId === event.contactId);
            if (contactApi?.contactId) {
              contactApi.onDestroy(() => {
                setSelectedContact(null);
              });
              setSelectedContact(contactApi);
            } else {
              setSelectedContact(null);
            }
          });
        } else {
          setSelectedContactId('');
          setSelectedContact(null);
        }
      });
    }
  }, [ccpInitialized]);

  // Once agent is set, get their CLID configuration
  useEffect(() => {
    if (agent) {
      try {
        const agentConfig = agent.getConfiguration();
        getRequest('/api/user/' + agentConfig.username)
          .then((data) => {
            setAgentExternalConfig({ ...data.data });
          })
          .catch((error) => {
            throw error;
          });
      } catch (error) {
        log.error('Error retrieving agent configuration from server', error);
        setSnackbar({
          message: 'Error retrieving agent desktop config from server',
          timeout: 5000,
          open: true,
          severity: 'error',
        });
      }
    }
  }, [agent]);

  const getSelectedContactId = (): string => {
    return selectedContactId;
  };

  const ccpLogout = useCallback(async () => {
    if (ccpInitialized) {
      try {
        // Call connect logout endpoint
        await fetch(
          `https://${config.CONNECT_INSTANCE_DOMAIN}/connect/logout`,
          {
            credentials: 'include',
            mode: 'no-cors',
          }
        );
        connect.core.terminate();
      } catch (error) {
        log.error('Error triggering CCP Logout', error);
      }
    }
    try {
      signOut();
      setLogout(true);
    } catch (error) {
      log.error('Error with signOut() method', error);
    }
  }, [ccpInitialized, config, log]);

  const agentOfflineCcpLogout = useCallback(async () => {
    if (agent) {
      // setLogout(true);
      let states: connect.AgentStateDefinition[] | undefined = undefined;
      try {
        states = agent.getAgentStates();
      } catch (error) {
        log.error('Error getting agent states', error);
      }
      if (states) {
        const offlineState = states.find(
          (state) => state.type === 'offline'
        ) as connect.AgentStateDefinition;
        if (!offlineState) {
          log.error('UNABLE TO FIND OFFLINE STATE FOR AGENT', states);
          setSnackbar({
            message: t('snackbarAgentOfflineError'),
            timeout: 6000,
            open: true,
            severity: 'error',
          });
          return await ccpLogout();
        } else {
          agent.setState(offlineState, {
            success: async () => await ccpLogout(),
            failure: (error) => {
              log.error(
                'AN ERROR OCCURRED WHEN SETTING THE AGENT STATE TO OFFLINE',
                error
              );
              setSnackbar({
                message: t('snackbarAgentOfflineError'),
                timeout: 6000,
                open: true,
                severity: 'error',
              });
            },
          });
        }
      }
    }
  }, [agent, ccpLogout, log, setSnackbar, t, setLogout]);

  useEffect(() => {
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          break;
        case 'signedOut':
          break;
        case 'tokenRefresh':
          break;
        case 'tokenRefresh_failure':
          log.warn('Token refesh failure, logging out');
          agentOfflineCcpLogout();
          break;
        case 'signInWithRedirect':
          break;
        case 'signInWithRedirect_failure':
          break;
        case 'customOAuthState':
          break;
      }
    });
  }, [agentOfflineCcpLogout]);

  const providerValue = {
    ccpInitialized,
    ccpInitFailed,
    ccpInitFailedStatus,
    selectedContactId,
    selectedContact,
    agent,
    agentExternalConfig,
    getSelectedContactId,
    ccpLogout,
    setCcpInitialized,
    setCcpInitFailed,
    setCcpInitFailedStatus,
    setSelectedContactId,
    setSelectedContact,
    updateAgent,
    setAgentExternalConfig,
    agentOfflineCcpLogout,
  };

  return (
    <ContactContext.Provider value={providerValue}>
      {children}
    </ContactContext.Provider>
  );
}
