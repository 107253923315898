import { useEffect, useState } from 'react';
import 'amazon-connect-streams';
import { useQuickConnectionContext } from '../providers/QuickConnectProvider';
import {
  CircularProgress,
  Container,
  Divider,
  Grid,
  List,
  Paper,
  Typography,
} from '@mui/material';
// import { logger } from '../utilities/logger';
import { useTheme } from '@mui/material/styles';
import { NestedQuickConnectList } from './NestedQuickConnectList';
import { FlatQuickConnectList } from './FlatQuickConnectList';
import { QuickConnectControls } from './QuickConnectControls';
import { useConfigContext } from '../providers/ConfigProvider';
import { useTranslation } from 'react-i18next';

interface IQuickConnectsProps {
  selectedContact: connect.Contact | null;
}

export const QuickConnectsError = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      flexGrow={1}
      paddingLeft={1}
      paddingTop={2}
      paddingRight={1}
      paddingBottom={1}
      position={'relative'}
      height={'100%'}
      className='contact-info-container'
    >
      <Typography>{t('quickConnectFallbackMessage')}</Typography>
    </Grid>
  );
};

export const QuickConnects = ({ selectedContact }: IQuickConnectsProps) => {
  // const log = logger();
  const theme = useTheme();

  // agentEndpoints are the endpoints associated with the queueARNs specified in queueARNs
  const { agentEndpoints } = useQuickConnectionContext();
  const { config } = useConfigContext();
  const { t } = useTranslation();
  const [selectedQc, setSelectedQc] = useState<string>('');

  const setQcHandler = (endpointId: string) => {
    setSelectedQc(endpointId);
  };

  // Account for agent config changes in QC selection
  // If a QC is selected and it's removed from agent config, we remove it from state
  useEffect(() => {
    if (
      agentEndpoints &&
      agentEndpoints.endpoints &&
      agentEndpoints.endpoints.length &&
      selectedQc
    ) {
      const found = agentEndpoints.endpoints.find(
        (endpoint) => endpoint.endpointId === selectedQc
      );
      if (!found) {
        setSelectedQc('');
      }
    }
  }, [agentEndpoints, selectedQc, selectedContact]);

  useEffect(() => {
    setSelectedQc('');
  }, [selectedContact]);

  const getQuickConnectList = () => {
    // Get QC category config
    const categories = config?.quickConnectCategories || [];

    // Fallback if agent has no quick connects
    if (
      !agentEndpoints ||
      !agentEndpoints.endpoints ||
      !agentEndpoints.endpoints.length
    ) {
      return null;
    }

    // Create list
    if (!categories || !categories?.length) {
      // Fallback if no category config
      return (
        <FlatQuickConnectList
          agentEndpoints={agentEndpoints}
          selectedContact={selectedContact}
          setSelectedQc={setQcHandler}
          selectedQc={selectedQc}
        />
      );
    }
    return (
      <NestedQuickConnectList
        agentEndpoints={agentEndpoints || { addresses: [], endpoints: [] }}
        selectedContact={selectedContact}
        quickConnectConfig={categories}
        setSelectedQc={setQcHandler}
        selectedQc={selectedQc}
      />
    );
  };

  const LoadingSpinner = () => {
    return (
      <Grid height={'100%'}>
        <Grid
          container
          id='quick-connectsloading-container'
          height={'100%'}
          justifyContent={'center'}
          alignContent={'center'}
          flexDirection={'column'}
        >
          <Grid>
            <Grid container justifyContent={'center'}>
              <CircularProgress />
            </Grid>
          </Grid>
          <Grid>
            <Typography
              color={theme.palette.info.main}
              margin='.5rem'
              variant='h6'
            >
              {t('quickConnectsLoading')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      flexGrow={1}
      paddingLeft={1}
      paddingTop={2}
      paddingRight={1}
      paddingBottom={1}
      height={'100%'}
      className='quick-connect-container'
      flexDirection={'column'}
    >
      {!agentEndpoints ? (
        <LoadingSpinner />
      ) : (
        <Container
          maxWidth={'sm'}
          disableGutters
          sx={{ margin: 0, padding: 0 }}
        >
          <Paper sx={{ width: '100%', height: '40rem' }}>
            <List
              sx={{
                width: '100%',
                overflow: 'auto',
                height: '34rem',
              }}
            >
              {getQuickConnectList()}
            </List>
            <Divider />
            <QuickConnectControls
              selectedQc={selectedQc}
              setSelectedQc={setSelectedQc}
            />
          </Paper>
        </Container>
      )}
    </Grid>
  );
};
