import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useContactContext } from '../providers/ContactProvider';
import { useAppStateContext } from '../providers/AppStateProvider';
import { useTranslation } from 'react-i18next';
import { useQuickConnectionContext } from '../providers/QuickConnectProvider';

interface IQuickConnectColdTransferButtonProps {
  selectedQc: string;
  setSelectedQc: (state: string) => void;
}

export const QuickConnectColdTransferButton = ({
  selectedQc,
  setSelectedQc,
}: IQuickConnectColdTransferButtonProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { agent, selectedContact } = useContactContext();
  const { agentEndpoints } = useQuickConnectionContext();
  const { setSnackbar } = useAppStateContext();
  const { t } = useTranslation();

  const getDisabled = () => {
    if (!selectedQc) {
      return true;
    }
    return false;
  };

  const clearSelectedQc = () => setSelectedQc('');

  const endpoint =
    agentEndpoints &&
    agentEndpoints.endpoints.find(
      (endpoint) => endpoint.endpointId === selectedQc
    );

  const clickHandler = () => {
    if (agent && endpoint) {
      if (selectedContact) {
        setLoading(true);
        selectedContact.addConnection(endpoint, {
          success: () => {
            setLoading(false);
            const contact = agent.getContacts(connect.ContactType.VOICE)[0];
            contact.getAgentConnection().destroy({
              success: () => {
                setSnackbar({
                  message: t('snackbarQuickConnectColdTransferSuccess'),
                  timeout: 6000,
                  open: true,
                  severity: 'success',
                });
              },
              failure: () => {
                setSnackbar({
                  message: t(
                    'snackbarQuickConnectColdTransferDisconnectFailure'
                  ),
                  timeout: 6000,
                  open: true,
                  severity: 'warning',
                });
              },
            });
            clearSelectedQc();
          },
          failure: () => {
            setLoading(false);
            setSnackbar({
              message: t('snackbarQuickConnectColdTransferFailure'),
              timeout: 6000,
              open: true,
              severity: 'warning',
            });
          },
        });
      }
    }
  };

  return (
    <LoadingButton
      disabled={getDisabled()}
      size='large'
      variant='contained'
      sx={{}}
      onClick={clickHandler}
      loading={loading}
    >
      {t('quickConnectButtonColdTransfer')}
    </LoadingButton>
  );
};
