// STRING UTILS
export const capitalizeWord = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const camelToFlat = (camel: string) => {
  const camelCase = camel.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ');
  let flat = '';
  camelCase.forEach((word) => {
    flat = flat + word.charAt(0).toUpperCase() + word.slice(1) + ' ';
  });
  return flat.trim();
};

// QUICK CONNECT UTILS
export const displayQc = (
  qc: connect.Endpoint,
  selectedContact: connect.Contact | null
) => {
  if (!selectedContact || !selectedContact.isConnected()) {
    if (
      selectedContact?.getType() === connect.ContactType.CHAT &&
      selectedContact.getState().type === connect.ContactStateType.ENDED
    ) {
      // Selected contact is Chat in ACW - a QC will apply to a new voice contact
      return qc.type === 'phone_number';
    }
    // No active contacts, show "external" quick connects only
    return qc.type === 'phone_number';
  } else if (
    selectedContact.isConnected() &&
    selectedContact.getType() !== connect.ContactType.VOICE
  ) {
    // Active contact, but non-voice contact, so filter out "external" quick connects
    return qc.type !== 'phone_number';
  } else {
    // Active voice contact, show all types of quick connects
    return true;
  }
};
