import React, { useContext, useState } from 'react';
import { logger } from '../utilities/logger';
import { Severity, SnackbarProps } from '../types';

interface IAppStateContext {
  snackbar: SnackbarProps;
  setSnackbar: (state: SnackbarProps) => void;
  resetSnackbar: () => void;
}

const AppStateContext = React.createContext<IAppStateContext | null>(null);

export function useAppStateContext() {
  const state = useContext(AppStateContext);

  if (!state) {
    throw new Error('useAppStateContext must be used within AppStateProvider');
  }

  return state;
}

export function AppStateProvider({ children }: React.PropsWithChildren) {
  const log = logger();
  const [snackbar, setSnackbar] = useState<SnackbarProps>({
    message: '',
    timeout: 1000,
    open: false,
    severity: 'success',
  });

  const resetSnackbar = () => {
    const snackbarDefault = {
      ...snackbar,
    } as SnackbarProps;
    snackbarDefault.open = false;
    setSnackbar({ ...snackbarDefault });
  };

  const providerValue = {
    snackbar,
    setSnackbar,
    resetSnackbar,
  };

  return (
    <AppStateContext.Provider value={providerValue}>
      {children}
    </AppStateContext.Provider>
  );
}
