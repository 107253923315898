import { useCallback } from 'react';
import {
  IconButton,
  MenuItem,
  Unstable_Grid2 as Grid,
  Paper,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { useAppStateContext } from '../providers/AppStateProvider';
import { useContactContext } from '../providers/ContactProvider';
import { useApiContext } from '../providers/ApiProvider';
import { InfoOutlined } from '@mui/icons-material';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { logger } from '../utilities/logger';
import parsePhoneNumber from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

// Error Boundary Component
const ClidSettingsFallback = () => {
  const { t } = useTranslation();
  return <Typography>{t('lineAppearanceFallback')}</Typography>;
};

// Regular Component
export const ClidSettings = () => {
  const log = logger();
  const { setSnackbar } = useAppStateContext();
  const { putRequest } = useApiContext();
  const { t } = useTranslation();
  const { agentExternalConfig, setAgentExternalConfig, agent } =
    useContactContext();

  const agentDid = agentExternalConfig?.assignedDid as string;
  const agentDidFormatted = parsePhoneNumber(agentDid);

  const clidSettingsErrorHandler = (error: Error, info: React.ErrorInfo) => {
    setSnackbar({
      message: t('snackbarLineAppearanceError'),
      timeout: 10000,
      open: true,
      severity: 'warning',
    });
  };

  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      useDidForOutbound: agentExternalConfig?.useDidForOutbound
        ? 'secondaryLine'
        : 'primaryLine',
    },
    mode: 'onChange',
  });

  const watchSetting = watch('useDidForOutbound');

  if (!agentExternalConfig) {
    throw new Error(
      'AGENT EXTERNAL CONFIG MUST BE TRUTHY TO RENDER THE PRIMARY SECONDARY LINE COMPONENT'
    );
  }

  const onSubmit = async (data: any) => {
    try {
      const agentConfig = agent?.getConfiguration();
      const username = agentConfig?.username;
      const body = {
        assignedDid: agentExternalConfig.assignedDid,
        useDidForOutbound:
          data.useDidForOutbound === 'primaryLine' ? false : true,
      };
      const response = await putRequest(`/api/user/clid/${username}`, body);
      setAgentExternalConfig({ ...response.data });
      const newValue = response.data.useDidForOutbound
        ? 'secondaryLine'
        : 'primaryLine';
      reset({ useDidForOutbound: newValue });
    } catch (error) {
      log.error('ERROR UPDATING AGENT PRIMARY SECONDARY LINE SETTINGS', error);
      throw error;
    }
  };

  const submitButtonDisabled = useCallback(() => {
    if (
      watchSetting === 'primaryLine' &&
      agentExternalConfig.useDidForOutbound === false
    )
      return true;
    if (
      watchSetting === 'secondaryLine' &&
      agentExternalConfig.useDidForOutbound === true
    )
      return true;
    return false;
  }, [watchSetting, agentExternalConfig.useDidForOutbound]);

  // t('key', { what: 'i18next', how: 'great' });
  return (
    <ErrorBoundary
      fallbackRender={ClidSettingsFallback}
      onError={clidSettingsErrorHandler}
    >
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: '.5rem',
          paddingTop: '.5rem',
          paddingBottom: '.5rem',
        }}
      >
        <Grid container alignItems={'center'}>
          <Typography fontSize='1.1rem'>{t('lineAppearanceTitle')}</Typography>
          <Tooltip title={t('lineAppearanceToolTip')} arrow>
            <IconButton size='small' color='primary' disableTouchRipple>
              <InfoOutlined sx={{ fontSize: '1rem' }} />
            </IconButton>
          </Tooltip>
        </Grid>
        <form style={{ width: '100%', padding: '.5rem' }}>
          <Grid container flexDirection='row' xs={12} alignItems={'center'}>
            <Controller
              name='useDidForOutbound'
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => {
                return (
                  <Grid flexGrow={1} sx={{ marginRight: '.5rem' }}>
                    <Select
                      onChange={onChange}
                      id='line-select'
                      value={value}
                      sx={{ width: '100%' }}
                      size='small'
                    >
                      <MenuItem value='primaryLine'>
                        {t('lineAppearancePrimary')}
                      </MenuItem>
                      <MenuItem value='secondaryLine'>
                        {t('lineAppearanceSecondary', {
                          number: agentDidFormatted?.formatInternational(),
                        })}
                      </MenuItem>
                    </Select>
                  </Grid>
                );
              }}
            />
            <Grid>
              <LoadingButton
                onClick={handleSubmit(onSubmit)}
                loading={isSubmitting}
                variant='contained'
                disabled={submitButtonDisabled()}
              >
                {t('lineAppearanceUpdate')}
              </LoadingButton>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </ErrorBoundary>
  );
};
