import { MetricsUnit } from '../types';

export const agentMetricsLookup: { [key: string]: { unit: MetricsUnit } } = {
  ABANDONMENT_RATE: { unit: 'PERCENT' },
  AGENT_ADHERENT_TIME: { unit: 'SECONDS' },
  AGENT_ANSWER_RATE: { unit: 'PERCENT' },
  AGENT_NON_ADHERENT_TIME: { unit: 'SECONDS' },
  AGENT_NON_RESPONSE: { unit: 'COUNT' },
  AGENT_NON_RESPONSE_WITHOUT_CUSTOMER_ABANDONS: { unit: 'COUNT' },
  AGENT_OCCUPANCY: { unit: 'PERCENTAGE' },
  AGENT_SCHEDULE_ADHERENCE: { unit: 'PERCENT' },
  AGENT_SCHEDULED_TIME: { unit: 'SECONDS' },
  AVG_ABANDON_TIME: { unit: 'SECONDS' },
  AVG_ACTIVE_TIME: { unit: 'SECONDS' },
  AVG_AFTER_CONTACT_WORK_TIME: { unit: 'SECONDS' },
  AVG_AGENT_CONNECTING_TIME: { unit: 'SECONDS' },
  AVG_AGENT_PAUSE_TIME: { unit: 'SECONDS' },
  AVG_CONTACT_DURATION: { unit: 'SECONDS' },
  AVG_CONVERSATION_DURATION: { unit: 'SECONDS' },
  AVG_GREETING_TIME_AGENT: { unit: 'SECONDS' },
  AVG_HANDLE_TIME: { unit: 'SECONDS' },
  AVG_HOLD_TIME: { unit: 'SECONDS' },
  AVG_HOLD_TIME_ALL_CONTACTS: { unit: 'SECONDS' },
  AVG_HOLDS: { unit: 'COUNT' },
  AVG_INTERACTION_AND_HOLD_TIME: { unit: 'SECONDS' },
  AVG_INTERACTION_TIME: { unit: 'SECONDS' },
  AVG_INTERRUPTIONS_AGENT: { unit: 'COUNT' },
  AVG_INTERRUPTION_TIME_AGENT: { unit: 'SECONDS' },
  AVG_NON_TALK_TIME: { unit: 'SECONDS' },
  AVG_TALK_TIME: { unit: 'SECONDS' },
  AVG_TALK_TIME_AGENT: { unit: 'SECONDS' },
  AVG_TALK_TIME_CUSTOMER: { unit: 'SECONDS' },
  CONTACTS_ABANDONED: { unit: 'COUNT' },
  CONTACTS_HANDLED: { unit: 'COUNT' },
  CONTACTS_HOLD_ABANDONS: { unit: 'COUNT' },
  CONTACTS_ON_HOLD_AGENT_DISCONNECT: { unit: 'COUNT' },
  CONTACTS_ON_HOLD_CUSTOMER_DISCONNECT: { unit: 'COUNT' },
  CONTACTS_PUT_ON_HOLD: { unit: 'COUNT' },
  CONTACTS_TRANSFERRED_OUT_EXTERNAL: { unit: 'COUNT' },
  CONTACTS_TRANSFERRED_OUT_INTERNAL: { unit: 'PERCENT' },
  CONTACTS_TRANSFERRED_OUT: { unit: 'COUNT' },
  PERCENT_NON_TALK_TIME: { unit: 'PERCENTAGE' },
  PERCENT_TALK_TIME: { unit: 'PERCENTAGE' },
  PERCENT_TALK_TIME_AGENT: { unit: 'PERCENTAGE' },
  PERCENT_TALK_TIME_CUSTOMER: { unit: 'PERCENTAGE' },
  SUM_AFTER_CONTACT_WORK_TIME: { unit: 'SECONDS' },
  SUM_CONNECTING_TIME_AGENT: { unit: 'SECONDS' },
  SUM_CONTACT_TIME_AGENT: { unit: 'SECONDS' },
  SUM_ERROR_STATUS_TIME_AGENT: { unit: 'SECONDS' },
  SUM_HANDLE_TIME: { unit: 'SECONDS' },
  SUM_HOLD_TIME: { unit: 'SECONDS' },
  SUM_IDLE_TIME_AGENT: { unit: 'SECONDS' },
  SUM_INTERACTION_AND_HOLD_TIME: { unit: 'SECONDS' },
  SUM_INTERACTION_TIME: { unit: 'SECONDS' },
  SUM_NON_PRODUCTIVE_TIME_AGENT: { unit: 'SECONDS' },
  SUM_ONLINE_TIME_AGENT: { unit: 'SECONDS' },
};
