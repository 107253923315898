import 'amazon-connect-streams';
import { useEffect, useRef, useState } from 'react';
import {
  Backdrop,
  CircularProgress,
  Fade,
  Unstable_Grid2 as Grid,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AttributeTable } from './AttributeTable';
import { useConfigContext } from '../providers/ConfigProvider';
import { logger } from '../utilities/logger';
import { useTranslation } from 'react-i18next';

interface IContactInfoProps {
  selectedContact: connect.Contact | null;
}

export const ContactInfoError = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      flexGrow={1}
      paddingLeft={1}
      paddingTop={2}
      paddingRight={1}
      paddingBottom={1}
      position={'relative'}
      height={'100%'}
      className='contact-info-container'
    >
      <Typography>{t('unableToDisplayContactInfo')}</Typography>
    </Grid>
  );
};

export const ContactInfo = ({ selectedContact }: IContactInfoProps) => {
  const log = logger();
  const theme = useTheme();
  const [attributes, setAttributes] =
    useState<connect.AttributeDictionary | null>(null);

  const { config, configInitialLoading } = useConfigContext();
  const { t } = useTranslation();

  const selectedContactRef = useRef<connect.Contact | null>(null);
  selectedContactRef.current = selectedContact;

  useEffect(() => {
    if (selectedContact) {
      setAttributes(selectedContact.getAttributes());
      selectedContact.onRefresh((contact) => {
        if (
          contact &&
          contact.contactId &&
          selectedContactRef &&
          selectedContactRef.current &&
          selectedContactRef.current.contactId &&
          contact.contactId === selectedContactRef.current.contactId
        ) {
          setAttributes(contact.getAttributes());
        }
      });
    }
  }, [selectedContact]);

  const attributesToDisplay = [] as string[];

  if (attributes && attributes['attributesToDisplay']) {
    try {
      const parsed = JSON.parse(attributes.attributesToDisplay.value);
      attributesToDisplay.push(...parsed);
    } catch (error) {
      log.error(
        'Error parsing attributes to display, unable to display attributes. Please ensure attribute is stringified array of attribute names.',
        error
      );
    }
  }

  return (
    <Grid
      container
      flexGrow={1}
      paddingLeft={1}
      paddingTop={2}
      paddingRight={1}
      paddingBottom={1}
      position={'relative'}
      height={'100%'}
      className='contact-info-container'
    >
      <Backdrop
        open={configInitialLoading && !config}
        style={{
          position: 'absolute',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.background.paper,
          height: '100%',
        }}
        TransitionComponent={Fade}
      >
        <CircularProgress />
      </Backdrop>
      {selectedContact ? (
        <AttributeTable
          attributes={attributes}
          attributesToDisplay={attributesToDisplay}
        />
      ) : (
        <Typography>{t('noActiveOrSelectedContact')}</Typography>
      )}
    </Grid>
  );
};
