import { Card, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { UserMetric } from '../types';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { Gauge } from '@mui/x-charts/Gauge';

interface MetricProps {
  metric: UserMetric;
}

interface LabelProps {
  label: string;
}
const MetricLabel = ({ label }: LabelProps) => {
  const { t } = useTranslation();
  return (
    <Typography fontWeight='bold' align='center'>
      {t(`metrics.labels.${label}`)}
    </Typography>
  );
};

const PercentMetric = ({ metric }: MetricProps) => {
  return (
    <Grid component={Card} xs={4} sm={3} md={3} lg={3} xl={2}>
      <Grid
        container
        direction='column'
        alignItems={'center'}
        justifyContent={'center'}
        height='100%'
      >
        <Grid>
          <MetricLabel label={metric.name} />
        </Grid>
        <Grid>
          <Gauge
            width={100}
            height={100}
            value={metric.value}
            text={() => `${Math.trunc(metric.value as number)}%`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const PercentageMetric = ({ metric }: MetricProps) => {
  return (
    <Grid component={Card} xs={4} sm={3} md={3} lg={3} xl={2}>
      <Grid
        container
        direction='column'
        alignItems={'center'}
        justifyContent={'center'}
        height='100%'
      >
        <Grid>
          <MetricLabel label={metric.name} />
        </Grid>
        <Grid>
          <Gauge
            width={100}
            height={100}
            value={(metric.value as number) * 100}
            text={() => `${Math.trunc((metric.value as number) * 100)}%`}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

const SecondsMetric = ({ metric }: MetricProps) => {
  const theme = useTheme();

  const hours = Math.floor((metric.value as number) / 3600);
  const minutes = Math.floor(((metric.value as number) - hours * 3600) / 60);
  const seconds =
    Math.floor(metric.value as number) - hours * 3600 - minutes * 60;
  const timeString =
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0');
  return (
    <Grid component={Card} xs={4} sm={3} md={3} lg={3} xl={2}>
      <Grid
        container
        direction='column'
        alignItems={'center'}
        justifyContent={'center'}
        height='100%'
      >
        <Grid>
          <MetricLabel label={metric.name} />
        </Grid>
        <Grid>
          <Typography
            align={'center'}
            variant='h4'
            color={theme.palette.info.main}
          >
            {timeString}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const CountMetric = ({ metric }: MetricProps) => {
  const theme = useTheme();
  let value = 0;

  if (metric.value) {
    const hasDecimal = metric.value - Math.floor(metric.value) !== 0;
    if (hasDecimal) {
      value = parseFloat(metric.value.toFixed(1));
    } else {
      value = metric.value;
    }
  }

  return (
    <Grid component={Card} xs={4} sm={3} md={3} lg={3} xl={2}>
      <Grid
        container
        direction='column'
        alignItems={'center'}
        height={'100%'}
        justifyContent={'center'}
      >
        <Grid>
          <MetricLabel label={metric.name} />
        </Grid>
        <Grid container alignItems={'center'} justifyContent={'center'}>
          <Grid height={'100%'}>
            <Typography
              align={'center'}
              variant='h4'
              color={theme.palette.info.main}
            >
              {value}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export const MetricItem = ({ metric }: MetricProps) => {
  if (metric.unit === 'PERCENT') {
    return <PercentMetric metric={metric} />;
  }
  if (metric.unit === 'PERCENTAGE') {
    return <PercentageMetric metric={metric} />;
  }
  if (metric.unit === 'SECONDS') {
    return <SecondsMetric metric={metric} />;
  }
  if (metric.unit === 'COUNT') {
    return <CountMetric metric={metric} />;
  }
};
