import 'amazon-connect-streams';
import { useEffect, memo, useRef } from 'react';
import { getConfig } from '../config';
import { useContactContext } from '../providers/ContactProvider';
import { CCPBackdrop, CCPErrorBackdrop } from './CCPBackdrop';
import { logger } from '../utilities/logger';
import { CallLog, CallLogEntry } from '../types';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useAppStateContext } from '../providers/AppStateProvider';
import { useTranslation } from 'react-i18next';

export const CCPError = () => {
  return (
    <div
      style={{
        width: '100%',
        height: '50%',
        minHeight: 600,
        minWidth: 390,
        display: 'flex',
        boxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        zIndex: 0,
        position: 'relative',
      }}
    >
      <CCPErrorBackdrop />
      <div
        style={{
          width: '100%',
          height: '100%',
          minHeight: 600,
          minWidth: 390,
          display: 'flex',
        }}
      >
        I AM ERROR
      </div>
    </div>
  );
};

const CCP = () => {
  // DayJS Setup

  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  dayjs.extend(timezone);
  dayjs.extend(utc);
  dayjs.tz.setDefault(tz);

  const log = logger();
  const config = getConfig();
  const ref = useRef();

  const {
    setCcpInitialized,
    setCcpInitFailed,
    setCcpInitFailedStatus,
    updateAgent,
  } = useContactContext();
  const { setSnackbar } = useAppStateContext();
  const { t } = useTranslation();

  // Call Log maintenance in Local Storage
  // Retrieve Call Log from Local Storage or initialize a new object if it does not exist
  // Clean out any call records that occurred prior to the start of the current day
  // Update Call Log in Local Storage
  useEffect(() => {
    // Access Call Log
    const callLogString = localStorage.getItem('callLog');

    // If null, initialize empty object in localStorage and return
    if (!callLogString) {
      localStorage.setItem('callLog', JSON.stringify({}));
      return;
    }

    const callLog: CallLog = JSON.parse(callLogString) as CallLog;

    // Get callLog object keys
    let callLogKeys = [] as string[];
    if (callLog) {
      callLogKeys = Object.keys(callLog);
    }

    // CallLog should not be null, but TS

    // Create dayJS object for the start of the current day in the user's timezone
    const startOfDay = dayjs.tz().startOf('day');

    // Loop through call log and delete any entries that occur before the start of day timestamp
    for (const contactId of callLogKeys) {
      // Get start time and determine if call took place prior to the start of today
      const startTime = dayjs(callLog[contactId].startTime);
      const isBeforeToday = startTime.isBefore(startOfDay);

      // If call occurred before today, delete it from the call log
      if (isBeforeToday) {
        delete callLog[contactId];
      }
    }

    // Update in local storage
    localStorage.setItem('callLog', JSON.stringify(callLog));
  }, []);

  const callLogConnected = (contact: connect.Contact) => {
    // Access Call Log
    let callLogString = localStorage.getItem('callLog');

    // If null, initialize in localStorage (accounts for clearing local storage after initial app load)
    if (!callLogString) {
      localStorage.setItem('callLog', JSON.stringify({}));
      callLogString = JSON.stringify({});
    }

    const callLog = JSON.parse(callLogString) as CallLog;

    const initialContactId = contact.getInitialContactId();

    const callLogEntry: CallLogEntry = {
      contactId: initialContactId,
      phoneNumber: contact.getActiveInitialConnection()?.getEndpoint()
        ?.phoneNumber
        ? (contact.getActiveInitialConnection()?.getEndpoint()
            ?.phoneNumber as string)
        : 'unknown',
      startTime: dayjs().valueOf(),
      direction: contact.isInbound() ? 'inbound' : 'outbound',
      queueName: contact.getQueue().name ? contact.getQueue().name : 'unknown',
    };

    if (callLog && !callLog[initialContactId]) {
      callLog[initialContactId] = callLogEntry;
      localStorage.setItem('callLog', JSON.stringify(callLog));
    }
  };

  const callLogEnded = (contact: connect.Contact) => {
    const initialContactId = contact.getInitialContactId();

    // Access Call Log
    const callLogString = localStorage.getItem('callLog');
    if (!callLogString) {
      log.warn(
        `Local Storage does not contain a call log object, unable to update ending timestamp for contact ID ${initialContactId}. Call will be omitted from call log.`
      );
      return;
    }
    const callLog: CallLog = JSON.parse(callLogString as string);

    if (!callLog || typeof callLog !== 'object') {
      log.warn(
        'Local Storage call log object is either falsy or not of the correct data type. No call log information will be available for this call.'
      );
      return;
    }

    if (!callLog[initialContactId]) {
      log.warn(
        `Local Storage call log object does not contain a call log entry for contact ID ${initialContactId}, unable to update ending timestamp. Call will be omitted from call log.`
      );
    }

    const startOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    callLog[initialContactId].endTime = dayjs().valueOf();
    localStorage.setItem('callLog', JSON.stringify(callLog));
  };

  useEffect(() => {
    log.trace('CCP INIT START');
    if (typeof connect === 'undefined')
      throw new Error('global connect missing');
    //@ts-expect-error - TypeScript doesn't like ref.current being undefined
    connect.core.initCCP(ref.current, {
      ccpUrl: config.CCP_URL,
      loginUrl: config.CCP_SSO_URL,
      loginPopup: true,
      loginPopupAutoClose: true,
      loginOptions: {
        autoClose: true,
      },
      pageOptions: {
        enableAudioDeviceSettings: true,
        enablePhoneTypeSettings: true,
      },
      softphone: { allowFramedSoftphone: true },
      shouldAddNamespaceToLogs: true,
    });

    // use core to monitor initialization
    connect.core.onInitialized(() => {
      setCcpInitialized(true);
    });
    connect.core.onAuthFail(() => {
      log.trace('CCP Init: Auth Failure');
      setCcpInitFailed(true);
      setCcpInitFailedStatus('authFail');
    });
    connect.core.onAccessDenied(() => {
      log.trace('CCP Init: Access Denied');
      setCcpInitFailed(true);
      setCcpInitFailedStatus('accessDenied');
    });
    connect.core.onAuthorizeRetriesExhausted(() => {
      log.trace('CCP Init: Authorize Retries Exhausted');
      setCcpInitFailed(true);
      setCcpInitFailedStatus('authorizeRetriesExhausted');
    });
    connect.core.onCTIAuthorizeRetriesExhausted(() => {
      log.trace('CCP Init: CTI Authorize Retries Exhausted');
      setCcpInitFailed(true);
      setCcpInitFailedStatus('ctiAuthorizeRetriesExhausted');
    });
    connect.core.onIframeRetriesExhausted(() => {
      log.trace('CCP Init: IFrame Retries Exhausted');
      setCcpInitFailed(true);
      setCcpInitFailedStatus('iframeRetriesExceeded');
    });
    // @ts-expect-error getEventBus isn't typed
    const eventBus = connect.core.getEventBus();
    eventBus.subscribe(connect.EventType.TERMINATED, () => {
      log.trace('CCP Terminated');
      setCcpInitialized(false);
      connect.core.terminate();
    });
    connect.agent((agent) => {
      updateAgent(agent);
    });
    connect.contact((contact) => {
      contact.onConnected((contact) => {
        if (
          contact.getType() === connect.ContactType.VOICE ||
          contact.getType() === connect.ContactType.QUEUE_CALLBACK
        ) {
          callLogConnected(contact);
        }
      });
      contact.onEnded((contact) => {
        if (
          contact.getType() === connect.ContactType.VOICE ||
          contact.getType() === connect.ContactType.QUEUE_CALLBACK
        ) {
          callLogEnded(contact);
        }
      });
      contact.onIncoming((contact) => {
        if (contact.getType() === connect.ContactType.QUEUE_CALLBACK) {
          contact.accept({
            success: () => {
              setSnackbar({
                message: t('snackbarAutoAcceptSuccess'),
                timeout: 10000,
                open: true,
                severity: 'success',
              });
            },
            failure: (error) => {
              setSnackbar({
                message: t('snackbarCallbackAutoAcceptError'),
                timeout: 6000,
                open: true,
                severity: 'warning',
              });
              log.error('ERROR AUTO-ACCEPTING QUEUED CALLBACK', error);
            },
          });
        }
      });
    });
  }, [
    ref,
    config.CCP_URL,
    config.CCP_SSO_URL,
    setCcpInitialized,
    setCcpInitFailed,
    setCcpInitFailedStatus,
  ]);

  return (
    <div
      style={{
        width: '100%',
        height: '50%',
        minHeight: 600,
        minWidth: 380,
        display: 'flex',
        boxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
        zIndex: 0,
        position: 'relative',
      }}
    >
      <CCPBackdrop />
      <div
        //@ts-expect-error - TypeScript doesn't like ref.current being undefined
        ref={ref}
        style={{
          width: '100%',
          height: '100%',
          minHeight: 600,
          minWidth: 380,
          display: 'flex',
        }}
      ></div>
    </div>
  );
};

export const ConnectCCP = memo(CCP);
