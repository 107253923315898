import { QuickConnectListItem } from './QuickConnectListItem';
import { displayQc } from '../utilities';

interface IFlatQuickConnectListProps {
  agentEndpoints: connect.GetEndpointsResult;
  selectedContact: connect.Contact | null;
  setSelectedQc: (id: string) => void;
  selectedQc: string;
}

export const FlatQuickConnectList = ({
  agentEndpoints,
  selectedContact,
  setSelectedQc,
  selectedQc,
}: IFlatQuickConnectListProps) => {
  /*
    Used if quick connect config is an empty object
      - Create flat list of endpoint objects taking conditions into account
      - Sort endpoints alphabetically
      - Render flat list 
  */

  const qcArray = [];

  agentEndpoints.endpoints.sort((a, b) => a.name.localeCompare(b.name));
  for (const endpoint of agentEndpoints.endpoints) {
    displayQc(endpoint, selectedContact) &&
      qcArray.push(
        <QuickConnectListItem
          endpoint={endpoint}
          flat={true}
          key={endpoint.endpointId}
          setSelectedQc={setSelectedQc}
          selectedQc={selectedQc}
        />
      );
  }
  return <>{qcArray}</>;
};
