import {
  Divider,
  Unstable_Grid2 as Grid,
  IconButton,
  List,
  ListItem,
  Pagination,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  AddIcCall,
  Call,
  CallMade,
  CallReceived,
  ContentCopy,
} from '@mui/icons-material';
import { logger } from '../utilities/logger';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { CallLogEntry, CallLog as CallLogInterface } from '../types';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import duration from 'dayjs/plugin/duration';
import parsePhoneNumber from 'libphonenumber-js';
import { useAppStateContext } from '../providers/AppStateProvider';
import { useContactContext } from '../providers/ContactProvider';

export const CallLogError = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      flexGrow={1}
      paddingLeft={1}
      paddingTop={2}
      paddingRight={1}
      paddingBottom={1}
      position={'relative'}
      height={'100%'}
      className='call-log-container'
    >
      <Typography>{t('callLog.errorBoundaryMessage')}</Typography>
    </Grid>
  );
};

export const CallLog = () => {
  dayjs.extend(localizedFormat);
  dayjs.extend(duration);
  const theme = useTheme();
  const log = logger();

  // Component Context
  const { t } = useTranslation();
  const { setSnackbar } = useAppStateContext();
  const { agent, selectedContact } = useContactContext();

  // Component State
  const [callLog, setCallLog] = useState<CallLogEntry[] | null>(null);
  const [page, setPage] = useState<number>(1);
  const [pageLength, setPageLength] = useState<number>(6);

  const createLogArray = (callLog: CallLogInterface) => {
    const callLogArray: CallLogEntry[] = [];

    // Convert call log object into an array of entries
    for (const contactId in callLog) {
      // Only include items with an end time
      if (callLog[contactId].endTime) {
        callLogArray.push(callLog[contactId]);
      }
    }

    // Sort array of entries by start timestamp, most recent first
    callLogArray.sort((x, y) => y.startTime - x.startTime);

    return callLogArray;
  };

  const updateCallLog = useCallback(() => {
    const callLogString = localStorage.getItem('callLog');
    if (!callLogString) {
      return;
    }
    // setCallLog(JSON.parse(callLogString) as CallLogInterface);
    const callLogArray = createLogArray(
      JSON.parse(callLogString) as CallLogInterface
    );

    setCallLog(callLogArray);
  }, [setCallLog]);

  // Get Call Log from Local Storage at init
  useEffect(() => {
    const callLogString = localStorage.getItem('callLog');
    if (!callLogString) {
      return;
    }

    const callLogArray = createLogArray(
      JSON.parse(callLogString) as CallLogInterface
    );

    setCallLog(callLogArray);

    connect.contact((contact) => {
      if (
        contact.getType() === connect.ContactType.VOICE ||
        contact.getType() === connect.ContactType.QUEUE_CALLBACK
      ) {
        contact.onDestroy(() => {
          updateCallLog();
        });
      }
    });
  }, []);

  const currentData = (data: any[]) => {
    const begin = (page - 1) * pageLength;
    const end = begin + pageLength;
    return data.slice(begin, end);
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const CopyToClipboardButton = ({ value }: { value: string }) => {
    const handleClick = () => {
      navigator.clipboard.writeText(value);
      setSnackbar({
        message: t('snackbarCopiedToClipboard'),
        timeout: 5000,
        open: true,
        severity: 'success',
      });
    };
    return (
      <Tooltip title={t('callLog.tooltipCopyButton')} arrow>
        <IconButton onClick={handleClick} size='small'>
          <ContentCopy sx={{ fontSize: '.9rem' }} />
        </IconButton>
      </Tooltip>
    );
  };

  const ClickToCallButton = ({ endpoint }: { endpoint: string }) => {
    const type = selectedContact ? selectedContact.getType() : '';

    const newCall = (endpoint: string, errorMessage: string) => {
      if (agent) {
        return agent.connect(connect.Endpoint.byPhoneNumber(endpoint), {
          failure: () => {
            setSnackbar({
              message: errorMessage,
              timeout: 6000,
              open: true,
              severity: 'warning',
            });
          },
        });
      }
    };

    const newConnection = (
      endpoint: string,
      errorMessage: string,
      selectedContact: connect.Contact
    ) => {
      selectedContact.addConnection(connect.Endpoint.byPhoneNumber(endpoint), {
        failure: () => {
          setSnackbar({
            message: errorMessage,
            timeout: 6000,
            open: true,
            severity: 'warning',
          });
        },
      });
    };

    const handleClick = () => {
      if (agent) {
        const contacts = agent.getContacts();
        if (!selectedContact && !contacts.length) {
          // THERE ARE NO ACTIVE CONTACTS, CREATE OUTBOUND VOICE CONTACT
          newCall(endpoint, t('snackbarClickToCallError'));
        } else if (
          selectedContact &&
          (type === connect.ContactType.VOICE ||
            type === connect.ContactType.QUEUE_CALLBACK)
        ) {
          // THERE'S A CALL IN PROGRESS, ADD A NEW CONNECTION
          newConnection(
            endpoint,
            t('snackbarClickToCallAddConnectionError'),
            selectedContact
          );
        }
      }
    };

    const CallButtonIcon = () => {
      if (agent) {
        const contacts = agent.getContacts();
        if (!selectedContact && !contacts.length) {
          // THERE ARE NO ACTIVE CONTACTS
          return <Call sx={{ fontSize: '.9rem' }} />;
        } else if (
          type === connect.ContactType.VOICE ||
          type === connect.ContactType.QUEUE_CALLBACK
        ) {
          // THERE'S A CALL IN PROGRESS
          return <AddIcCall sx={{ fontSize: '.9rem' }} />;
        }
      }
      return null;
    };

    const toolTipTitle = () => {
      if (agent) {
        const contacts = agent.getContacts();
        if (!selectedContact && !contacts.length) {
          // THERE ARE NO ACTIVE CONTACTS
          return t('callLog.toolTipClickToCall');
        } else if (
          type === connect.ContactType.VOICE ||
          type === connect.ContactType.QUEUE_CALLBACK
        ) {
          // THERE'S A CALL IN PROGRESS
          return t('callLog.toolTipClickToAddConnection');
        }
      }
      return null;
    };

    return (
      <Tooltip title={toolTipTitle()} arrow>
        <IconButton onClick={handleClick} size='small'>
          <CallButtonIcon />
        </IconButton>
      </Tooltip>
    );
  };

  const CallLogTitle = () => {
    return (
      <>
        <Grid>
          <Grid container>
            <Typography variant='h4' color={theme.palette.info.main}>
              {t('callLog.pageTitle')}
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          <Divider sx={{ margin: '1rem 0' }} />
        </Grid>
      </>
    );
  };

  const CallLogListItem = (logEntry: CallLogEntry) => {
    const direction = logEntry.direction;
    const icon =
      direction === 'inbound' ? (
        <CallReceived color='success' />
      ) : (
        <CallMade color='primary' />
      );
    const phoneNumber = logEntry.phoneNumber;
    const phoneNumberFormatted =
      parsePhoneNumber(phoneNumber)?.formatInternational();
    const startTimeDisplay = dayjs(logEntry.startTime).format('lll');
    const durationMs = dayjs(logEntry.endTime).diff(dayjs(logEntry.startTime));
    const duration = dayjs.duration(durationMs, 'milliseconds');
    const queue = logEntry.queueName;

    return (
      <ListItem key={logEntry.contactId}>
        <Grid container className='call-log-body' direction={'column'}>
          <Grid
            container
            spacing={'1rem'}
            justifyContent='center'
            alignItems='center'
          >
            <Grid>{icon}</Grid>
            <Grid>
              <Typography sx={{ fontWeight: 'medium' }}>
                {phoneNumberFormatted}
              </Typography>
            </Grid>
            <Grid paddingLeft={0} paddingRight={0}>
              -
            </Grid>
            <Grid>
              <Typography>{queue}</Typography>
            </Grid>
            {agent && (
              <Grid paddingLeft={0} paddingRight={0}>
                <ClickToCallButton endpoint={phoneNumber} />
              </Grid>
            )}
            <Grid paddingLeft={0} paddingRight={0}>
              <CopyToClipboardButton value={phoneNumber} />
            </Grid>
          </Grid>
          <Grid container spacing={'1rem'}>
            <Grid visibility='hidden'>{icon}</Grid>
            <Grid>
              <Grid container direction='column'>
                <Typography variant='body2' color={theme.palette.grey[700]}>
                  {startTimeDisplay}
                </Typography>
                <Typography variant='body2' color={theme.palette.grey[700]}>
                  {duration.format('HH:mm:ss')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItem>
    );
  };

  const CallLogList = () => {
    const listItems = [];
    const data = currentData(callLog ? callLog : []);

    for (const entry of data) {
      listItems.push(CallLogListItem(entry));
    }

    return (
      <Grid>
        <Grid container direction='column' alignItems='start'>
          <Grid height='35rem' width='100%'>
            <List
              sx={{
                width: '100%',
                overflow: 'auto',
                height: '100%',
                border: 1,
                borderColor: theme.palette.grey[300],
              }}
            >
              {listItems}
            </List>
          </Grid>
          <Grid width='100%'>
            <Grid container justifyContent={'center'} paddingTop={'1rem'}>
              <Pagination
                count={callLog ? Math.ceil(callLog.length / pageLength) : 0}
                onChange={handleChange}
                page={page}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid
      container
      flexGrow={1}
      paddingLeft={1}
      paddingTop={2}
      paddingRight={1}
      paddingBottom={1}
      height={'100%'}
      className='call-log-main-container'
      flexDirection={'column'}
    >
      <CallLogTitle />
      {!callLog || !callLog.length ? (
        <Typography>{t('callLog.noCallLogEntries')}</Typography>
      ) : (
        <CallLogList />
      )}
    </Grid>
  );
};
