import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { camelToFlat } from '../utilities';
import { useConfigContext } from '../providers/ConfigProvider';
import { useTranslation } from 'react-i18next';
// import { logger } from '../utilities/logger';

interface IAttributeTableProps {
  attributes: connect.AttributeDictionary | null;
  attributesToDisplay: string[];
}

export const AttributeTable = ({
  attributes,
  attributesToDisplay,
}: IAttributeTableProps) => {
  const { config } = useConfigContext();
  const { t } = useTranslation();
  // const log = logger();

  const attributeNameTranslations = config?.attributeNameTranslations
    ? config.attributeNameTranslations
    : {};
  const attributeValueTranslations = config?.attributeValueTranslations
    ? config.attributeValueTranslations
    : {};

  // Return message instead of table if attributes or config are missing
  if (!attributesToDisplay || !attributesToDisplay.length || !attributes) {
    return <Typography>{t('noInfoToDisplay')}</Typography>;
  }

  const createRow = (attributeName: string, attributeValue: string) => {
    return (
      <TableRow key={attributeName}>
        <TableCell>{attributeName}</TableCell>
        <TableCell width={'80%'}>{attributeValue}</TableCell>
      </TableRow>
    );
  };

  const rows = [] as JSX.Element[];

  for (const item in attributes) {
    if (attributes[item]) {
      if (!attributesToDisplay.includes(attributes[item].name)) {
        continue;
      }
      const name = attributeNameTranslations[attributes[item].name]
        ? attributeNameTranslations[attributes[item].name]
        : camelToFlat(attributes[item].name);

      const value = attributeValueTranslations[attributes[item].value]
        ? attributeValueTranslations[attributes[item].value]
        : camelToFlat(attributes[item].value);

      const row = createRow(name, value);
      rows.push(row);
    }
  }

  return (
    <Box sx={{ width: '100%' }}>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>{rows}</TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
