import { useEffect, useState } from 'react';
import {
  CircularProgress,
  Divider,
  Unstable_Grid2 as Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { LoadingButton } from '@mui/lab';
import { useApiContext } from '../providers/ApiProvider';
import { useContactContext } from '../providers/ContactProvider';
import { logger } from '../utilities/logger';
import { UserMetric, UserMetrics } from '../types';
import { useTheme } from '@mui/material/styles';
import { MetricItem } from './AgentMetricsItems';
import { agentMetricsLookup } from '../utilities/agentMetricList';
import { useConfigContext } from '../providers/ConfigProvider';
import { useAppStateContext } from '../providers/AppStateProvider';

dayjs.extend(localizedFormat);

export const AgentMetricsError = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      flexGrow={1}
      paddingLeft={1}
      paddingTop={2}
      paddingRight={1}
      paddingBottom={1}
      position={'relative'}
      height={'100%'}
      className='metrics-container'
    >
      <Typography>{t('metrics.errorBoundaryMessage')}</Typography>
    </Grid>
  );
};

export const AgentMetrics = () => {
  const theme = useTheme();
  const log = logger();

  // Component State
  const [lastUpdated, setLastUpdated] = useState<string | null>(null);
  const [refreshing, setRefreshing] = useState(false);
  const [metrics, setMetrics] = useState<UserMetrics | null>(null);

  // Component Context
  const { getRequest } = useApiContext();
  const { agent } = useContactContext();
  const { config } = useConfigContext();
  const { setSnackbar } = useAppStateContext();
  const { t } = useTranslation();

  const updateLastUpdated = () => {
    const now = dayjs();
    setLastUpdated(now.format('llll'));
  };

  const getAgentId = (agent: connect.Agent) => {
    const agentArn = agent.getConfiguration().agentARN;
    const agentId = agentArn.split('/').pop();
    if (!agentId) throw new Error('No agent ID');
    return agentId;
  };

  const getAgentMetrics = async (agentId: string): Promise<UserMetrics> => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!config?.agentMetrics) return [];
    const metrics = config.agentMetrics;
    const requestPath = `/api/usermetrics/${agentId}?tz=${tz}&metrics=${JSON.stringify(metrics)}`;
    const response = await getRequest(requestPath);

    // Metrics API can return either no metric or a metric with no value if no data is available
    // Here we make sure the metrics we're asking for are included in return
    // If not included in return, we assume zero values and create a placeholder so the zero value can be displayed in the UI
    const metricsToReturn = [];
    for (const metric of metrics) {
      const found = response.data.find(
        (item: UserMetric) => item.name === metric
      );
      if (!found) {
        if (!agentMetricsLookup[metric]) {
          log.warn(
            `Agent metric ${metric} was requested but is not included in the supported metrics list for this application. Metric will be omitted from display.`
          );
          continue;
        } else {
          metricsToReturn.push({
            name: metric,
            value: 0,
            unit: agentMetricsLookup[metric].unit,
          });
        }
      } else {
        metricsToReturn.push({ ...found });
      }
    }

    return metricsToReturn;
  };

  const handleUpdate = async () => {
    try {
      if (agent) {
        setRefreshing(true);
        const agentId = getAgentId(agent);
        const response = await getAgentMetrics(agentId);
        setMetrics(response);
        updateLastUpdated();
        setRefreshing(false);
      } else {
        log.error('Attempted to get metrics with no agent data');
        setSnackbar({
          message: t('snackbarErrorGettingMetrics'),
          timeout: 6000,
          open: true,
          severity: 'error',
        });
      }
    } catch (error) {
      setRefreshing(false);
      log.error('Error retrieving agent metrics', error);
      setSnackbar({
        message: t('snackbarErrorGettingMetrics'),
        timeout: 6000,
        open: true,
        severity: 'error',
      });
    }
  };

  useEffect(() => {
    if (agent) {
      try {
        const agentId = getAgentId(agent);
        getAgentMetrics(agentId).then((response) => {
          setMetrics(response);
          updateLastUpdated();
        });
      } catch (error) {
        log.error('Error retrieving agent metrics', error);
        setSnackbar({
          message: t('snackbarErrorGettingMetrics'),
          timeout: 6000,
          open: true,
          severity: 'error',
        });
      }
    }
  }, [agent]);

  const LoadingSpinner = () => {
    return (
      <Grid height={'100%'}>
        <Grid
          container
          id='loading-container'
          height={'100%'}
          justifyContent={'center'}
          alignContent={'center'}
          flexDirection={'column'}
        >
          <Grid>
            <Grid container justifyContent={'center'}>
              <CircularProgress />
            </Grid>
          </Grid>
          <Grid>
            <Typography
              color={theme.palette.info.main}
              margin='.5rem'
              variant='h6'
            >
              {t('metrics.loadingMetrics')}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const MetricsDisplay = () => {
    const theme = useTheme();

    return (
      <>
        <Grid>
          <Grid container>
            <Typography variant='h4' color={theme.palette.info.main}>
              {t('metrics.pageTitle')}
            </Typography>
          </Grid>
          <Grid container alignItems='center'>
            <Typography
              display={'flex'}
            >{`${t('metrics.lastUpdated')} ${lastUpdated}`}</Typography>
            <Tooltip title={t('metrics.refreshToolTip')} arrow>
              <IconButton size='small' color='primary' disableTouchRipple>
                <InfoOutlined sx={{ fontSize: '1rem' }} />
              </IconButton>
            </Tooltip>
            <LoadingButton
              sx={{ marginLeft: '1rem' }}
              variant='outlined'
              loading={refreshing}
              onClick={handleUpdate}
            >
              {t('metrics.refresh')}
            </LoadingButton>
          </Grid>
        </Grid>
        <Grid>
          <Divider sx={{ margin: '1rem 0' }} />
        </Grid>
        <Grid container flexGrow={1} gap={1}>
          {metrics?.map((metric: UserMetric) => {
            return <MetricItem key={metric.name} metric={metric} />;
          })}
        </Grid>
      </>
    );
  };

  return (
    <Grid
      container
      flexGrow={1}
      paddingLeft={1}
      paddingTop={2}
      paddingRight={1}
      paddingBottom={1}
      height={'100%'}
      className='metrics-container'
      flexDirection={'column'}
    >
      {metrics ? <MetricsDisplay /> : <LoadingSpinner />}
    </Grid>
  );
};
