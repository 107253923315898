import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

export const PageNotFound = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction='column'
      justifyContent='center'
      alignItems='center'
      flexGrow={1}
      height='100%'
    >
      <Typography
        color={theme.palette.info.main}
        variant='h6'
        margin='8px'
        textAlign={'center'}
      >
        {t('pageNotFound')}
      </Typography>
    </Grid>
  );
};
