import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useContactContext } from '../providers/ContactProvider';
import { ReportProblemOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export const CCPErrorBackdrop = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Backdrop
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
      }}
      open
    >
      <>
        <ReportProblemOutlined color={'error'} fontSize='large' />
        <Typography color={theme.palette.info.main} variant='h6' margin='8px'>
          {t('backdropCcpTitleError')}
        </Typography>
        <Typography
          textAlign={'center'}
          marginLeft={'24px'}
          marginRight={'24px'}
        >
          {t('backdropCcpMessageError')}
        </Typography>
      </>
    </Backdrop>
  );
};

export const CCPBackdrop = () => {
  const theme = useTheme();
  const { ccpInitialized, ccpInitFailed, ccpInitFailedStatus } =
    useContactContext();
  const { t } = useTranslation();

  const getBackgroundOpen = !ccpInitialized || ccpInitFailed;

  const getTitle = () => {
    switch (ccpInitFailedStatus) {
      case 'iframeRetriesExceeded':
        return t('backdropCcpTitleRetriesExceeded');
      case 'authFail':
        return t('backdropCcpTitleAuthFail');
      case 'accessDenied':
        return t('backdropCcpTitleAccessDenied');
      case 'authorizeRetriesExhausted':
        return t('backdropCcpTitleAuthRetriesExhausted');
      case 'ctiAuthorizeRetriesExhausted':
        return t('backdropCcpTitleCtiAuthRetriesExhausted');
      default:
        // CCP is initializing and no error state is set
        return t('backdropCcpTitleInitialzing');
    }
  };

  const getMessage = () => {
    switch (ccpInitFailedStatus) {
      case 'iframeRetriesExceeded':
        return t('backdropCcpMessageRetriesExceeded');
      case 'authFail':
        return t('backdropCcpMessageAuthFail');
      case 'accessDenied':
        return t('backdropCcpMessageAccessDenied');
      case 'authorizeRetriesExhausted':
        return t('backdropCcpMessageAuthRetriesExhausted');
      case 'ctiAuthorizeRetriesExhausted':
        return t('backdropCcpMessageCtiAuthRetriesExhausted');
      default:
        // CCP is initializing and no error state is set
        return t('backdropCcpMessageInitialzing');
    }
  };

  return (
    <Backdrop
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.paper,
      }}
      open={getBackgroundOpen}
    >
      <>
        {!ccpInitFailed && <CircularProgress />}
        {ccpInitFailed && (
          <ReportProblemOutlined color={'error'} fontSize='large' />
        )}
        <Typography color={theme.palette.info.main} variant='h6' margin='8px'>
          {getTitle()}
        </Typography>
        <Typography
          textAlign={'center'}
          marginLeft={'24px'}
          marginRight={'24px'}
        >
          {getMessage()}
        </Typography>
      </>
    </Backdrop>
  );
};
