import { useEffect, useState } from 'react';
import {
  ButtonGroup,
  Typography,
  Unstable_Grid2 as Grid,
  Paper,
  Tooltip,
  IconButton,
} from '@mui/material';
import { useContactContext } from '../providers/ContactProvider';
import { useApiContext } from '../providers/ApiProvider';
import { useAppStateContext } from '../providers/AppStateProvider';
import { LoadingButton } from '@mui/lab';
import { logger } from '../utilities/logger';
import { Severity } from '../types';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import { InfoOutlined } from '@mui/icons-material';
// import {
//   ArrowRight,
//   FiberManualRecord,
//   Pause,
//   Stop,
// } from '@mui/icons-material';

type Attribute = { name: string; value: string };

type RecordingActions = 'START' | 'STOP' | 'RESUME' | 'PAUSE';

type CcpRecordingStatus =
  | 'noStatus'
  | 'started'
  | 'paused'
  | 'resumed'
  | 'blocked'
  | 'stopped';

type ConnectRecordingState = 'on' | 'off' | 'blocked';

export const RecordingControlsError = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '8px',
        paddingTop: '12px',
        paddingBottom: '12px',
      }}
    >
      <Grid container spacing={'.5rem'}>
        <Grid>
          <Typography fontSize='1.1rem'>{`${t('callRecordingStatus')}: `}</Typography>
        </Grid>
        <Grid>
          <Typography
            fontSize='1.1rem'
            sx={{
              fontWeight: 'medium',
              color: theme.palette.error.main,
            }}
          >
            {t('ERRORSTATUS')}
          </Typography>
        </Grid>
      </Grid>
      <ButtonGroup
        variant='contained'
        aria-label='Basic button group'
        sx={{ marginTop: '8px' }}
      >
        <LoadingButton disabled>{t('start')}</LoadingButton>
        <LoadingButton disabled>{t('pause')}</LoadingButton>
        <LoadingButton disabled>{t('resume')}</LoadingButton>
        <LoadingButton disabled>{t('stop')}</LoadingButton>
      </ButtonGroup>
    </Paper>
  );
};

export const RecordingControls = () => {
  const log = logger();

  // Component State
  const [recordingStatus, setRecordingStatus] =
    useState<CcpRecordingStatus>('noStatus');
  const [initialContactId, setInitialContactId] = useState('');
  const [loadingAction, setLoadingAction] = useState('');
  const [contactId, setContactId] = useState('');
  const [contactAttributes, setContactAttributes] =
    useState<connect.AttributeDictionary>({});

  // Component Context
  const { selectedContact } = useContactContext();
  const { postRequest } = useApiContext();
  const { setSnackbar } = useAppStateContext();
  const { t } = useTranslation();
  const theme = useTheme();

  useEffect(() => {
    try {
      if (selectedContact) {
        if (selectedContact.getType() !== connect.ContactType.VOICE) {
          setRecordingStatus('noStatus');
          return;
        }
        selectedContact.onRefresh((contact) =>
          setContactAttributes(contact.getAttributes())
        );
        // const attributes = selectedContact.getAttributes();
        setInitialContactId(selectedContact.getInitialContactId());
        setContactId(selectedContact.getContactId());

        // Check attributes for past actions to determine most recent action if any
        const recordingActions = [] as Attribute[];
        for (const key in contactAttributes) {
          // Find any timestamps and push them to an array
          if (key.startsWith('recordingAction') && contactAttributes[key]) {
            recordingActions.push(contactAttributes[key]);
          }
        }
        if (recordingActions.length && recordingActions.length > 1) {
          // If array has length and has more than one item, sort it
          recordingActions.sort((a, b) => {
            // keys are name, value, but we already know the name starts with 'recordingAction'
            const aTimestamp = a.name;
            const bTimestamp = b.name;
            return aTimestamp.localeCompare(bTimestamp);
          });
        }

        if (recordingActions.length) {
          // Grab last/most recent action
          const mostRecentAction = recordingActions[
            recordingActions.length - 1
          ] as Attribute;
          const action = mostRecentAction.value as RecordingActions;
          // Set status based on most recent action
          if (action === 'START') {
            setRecordingStatus('started');
          } else if (action === 'RESUME') {
            setRecordingStatus('resumed');
          } else if (action === 'PAUSE') {
            setRecordingStatus('paused');
          } else if (action === 'STOP') {
            setRecordingStatus('stopped');
          }
        } else if (
          contactAttributes &&
          contactAttributes.initialRecordingState
        ) {
          if (contactAttributes.initialRecordingState.value === 'on') {
            setRecordingStatus('started');
          } else if (contactAttributes.initialRecordingState.value === 'off') {
            setRecordingStatus('noStatus');
          } else if (
            contactAttributes.initialRecordingState.value === 'blocked'
          ) {
            setRecordingStatus('blocked');
          }
        } else {
          // disable recording because we're not able to tell if it's active or not
          if (selectedContact.getType() === connect.ContactType.VOICE) {
            setRecordingStatus('blocked');
          }
        }
      } else {
        // Set back to defaults
        setRecordingStatus('noStatus');
        setContactId('');
        setInitialContactId('');
        setLoadingAction('');
        if (Object.keys(contactAttributes).length) {
          setContactAttributes({});
        }
      }
    } catch (error) {
      log.error(error);
    }
  }, [selectedContact, contactAttributes, log]);

  const contactType = selectedContact?.getType();

  const triggerSnackbar = (message: string, severity: Severity) => {
    setSnackbar({
      message: message,
      timeout: 6000,
      open: true,
      severity: severity,
    });
  };

  const handleClick = async (action: RecordingActions) => {
    const body = {
      action,
      contactId,
      initialContactId,
    };

    try {
      setLoadingAction(action);
      await postRequest('/api/recordingstatus', body);
      if (action === 'START') {
        setRecordingStatus('started');
      } else if (action === 'PAUSE') {
        setRecordingStatus('paused');
      } else if (action === 'RESUME') {
        setRecordingStatus('resumed');
      } else if (action === 'STOP') {
        setRecordingStatus('stopped');
      }
      setLoadingAction('');
    } catch (error) {
      setLoadingAction('');
      log.error(`ERROR UPDATING RECORDING STATE TO ${action}`, error);
      triggerSnackbar('Unable to update recording status', 'warning');
    }
  };

  const disabled = (action: RecordingActions) => {
    if (
      !selectedContact ||
      selectedContact.getState().type !== 'connected' ||
      recordingStatus === 'blocked' ||
      recordingStatus === 'stopped' ||
      contactType !== connect.ContactType.VOICE
    ) {
      return true;
    }

    if (loadingAction && loadingAction !== action) {
      // Another button has been clicked and the API call is in-progress, so avoid a second action being clicked
      return true;
    }

    if (action === 'START') {
      return recordingStatus !== 'noStatus';
    } else if (action === 'PAUSE') {
      return recordingStatus !== 'started' && recordingStatus !== 'resumed';
    } else if (action === 'RESUME') {
      return recordingStatus !== 'paused';
    } else if (action === 'STOP') {
      return recordingStatus !== 'started' && recordingStatus !== 'resumed';
    }
  };

  const status = () => {
    if (
      (selectedContact &&
        selectedContact.getState().type !==
          connect.ContactStateType.CONNECTED) ||
      !selectedContact
    ) {
      return t('NOTSTARTED');
    }
    // if (selectedContact && selectedContact.getState().type !== connect.ContactStateType.CONNECTED) {
    //   //
    // }
    if (recordingStatus === 'noStatus') {
      return t('NOTSTARTED');
    } else if (recordingStatus === 'started' || recordingStatus === 'resumed') {
      return t('RECORDING');
    } else if (recordingStatus === 'paused') {
      return t('PAUSED');
    } else if (recordingStatus === 'stopped') {
      return t('STOPPED');
    } else return t('BLOCKED');
  };

  const statusColor = () => {
    if (
      !selectedContact ||
      selectedContact.getState().type !== connect.ContactStateType.CONNECTED ||
      contactType !== connect.ContactType.VOICE
    ) {
      return theme.palette.grey[500];
    }
    if (recordingStatus === 'started' || recordingStatus === 'resumed') {
      return theme.palette.error.main;
    } else {
      return theme.palette.info.main;
    }
  };

  return (
    // <ProtectedComponent requiredSgId='='>
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: '8px',
        paddingTop: '12px',
        paddingBottom: '12px',
      }}
    >
      <Grid container alignItems={'center'}>
        <Grid>
          <Typography fontSize='1.1rem'>{`${t('callRecordingStatus')}: `}</Typography>
        </Grid>
        <Grid>
          <Typography
            fontSize='1.1rem'
            sx={{
              fontWeight: 'medium',
              color: statusColor(),
              marginLeft: '.5rem',
            }}
          >
            {status()}
          </Typography>
        </Grid>
        {(recordingStatus === 'stopped' ||
          recordingStatus === 'started' ||
          recordingStatus === 'resumed') &&
          selectedContact?.getState().type ===
            connect.ContactStateType.CONNECTED && (
            <Grid>
              <Tooltip title={t('tooltipRecordingStopped')} arrow>
                <IconButton size='small' color='primary' disableTouchRipple>
                  <InfoOutlined sx={{ fontSize: '1rem' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
        {recordingStatus === 'blocked' &&
          selectedContact?.getState().type ===
            connect.ContactStateType.CONNECTED &&
          contactType === connect.ContactType.VOICE && (
            <Grid>
              <Tooltip title={t('tooltipRecordingBlocked')} arrow>
                <IconButton size='small' color='primary' disableTouchRipple>
                  <InfoOutlined sx={{ fontSize: '1rem' }} />
                </IconButton>
              </Tooltip>
            </Grid>
          )}
      </Grid>
      <ButtonGroup
        variant='contained'
        aria-label='Basic button group'
        sx={{ marginTop: '8px' }}
      >
        <LoadingButton
          loading={loadingAction === 'START'}
          onClick={() => handleClick('START')}
          disabled={disabled('START')}
        >
          {t('start')}
        </LoadingButton>
        <LoadingButton
          loading={loadingAction === 'PAUSE'}
          onClick={() => handleClick('PAUSE')}
          disabled={disabled('PAUSE')}
        >
          {t('pause')}
        </LoadingButton>
        <LoadingButton
          loading={loadingAction === 'RESUME'}
          onClick={() => handleClick('RESUME')}
          disabled={disabled('RESUME')}
        >
          {t('resume')}
        </LoadingButton>
        <LoadingButton
          loading={loadingAction === 'STOP'}
          onClick={() => handleClick('STOP')}
          disabled={disabled('STOP')}
        >
          {t('stop')}
        </LoadingButton>
      </ButtonGroup>
    </Paper>
    // </ProtectedComponent>
  );
};
