import log from 'loglevel';
import { getConfig } from '../config';

export const logger = () => {
  const config = getConfig();
  const logger = log.getLogger('main');
  if (
    typeof config.ENVIRONMENT === 'string' &&
    config.ENVIRONMENT.toLowerCase() === 'dev'
  ) {
    logger.setLevel('TRACE');
  } else {
    logger.setLevel('WARN');
  }
  return logger;
};
