import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      agentDesktop: 'Agent Desktop',
      backdropCcpMessageAccessDenied:
        'CCP access denied. Please logout and login again. If the issue persists, please contact your administrator',
      backdropCcpMessageAuthFail:
        'CCP authorization has failed. Please logout and login again. If the issue persists, please contact your adiministrator',
      backdropCcpMessageAuthRetriesExhausted:
        'CCP authorization retries exhausted. Please logout and login again. If the issue persists, please contact your administrator',
      backdropCcpMessageCtiAuthRetriesExhausted:
        'CCP CTI authorization retries exhausted. Please logout and login again. If the issue persists, please contact your administrator',
      backdropCcpMessageError:
        'An error was encountered when attempting to load the Amazon Connect CCP. Please try refreshing this page. If the issue persists, please contact your administrator',
      backdropCcpMessageInitialzing:
        'Please ensure popups are allowed and allow time for login popup to automatically close',
      backdropCcpMessageRetriesExceeded:
        'Please ensure popups are allowed and refresh this page',
      backdropCcpTitleAccessDenied: 'CCP Access Denied',
      backdropCcpTitleAuthFail: 'CCP Authorization Failure',
      backdropCcpTitleAuthRetriesExhausted: 'CCP Authorization Failure',
      backdropCcpTitleCtiAuthRetriesExhausted: 'CCP Authorization Failure',
      backdropCcpTitleError: 'CCP Error',
      backdropCcpTitleInitialzing: 'Initializing CCP',
      backdropCcpTitleRetriesExceeded: 'Failure Initializing CCP',
      BLOCKED: 'BLOCKED',
      callRecordingStatus: 'Call Recording Status',
      chatTranscriptionStatus: 'Chat Transcription Status',
      contactInfo: 'Contact Info',
      ERRORSTATUS: 'ERROR',
      noInfoToDisplay: 'No Info to Display for Selected Contact',
      lineAppearanceFallback: 'Error Loading Line Apearance',
      lineAppearancePrimary: 'Primary ( Default outbound )',
      lineAppearanceSecondary: 'Secondary ( {{number}} )',
      lineAppearanceTitle: 'Line Appearance',
      lineAppearanceToolTip:
        'Set how your caller ID appears for outbound voice calls',
      lineAppearanceUpdate: 'Update',
      loginWithSso: 'LOGIN WITH SSO',
      loginAgain: 'LOGIN AGAIN',
      noActiveOrSelectedContact: 'No Active or Selected Contact',
      NOTSTARTED: 'NOT STARTED',
      pageNotFound: 'Page Not Found',
      pause: 'Pause',
      PAUSED: 'PAUSED',
      quickConnects: 'Quick Connects',
      quickConnectButtonCall: 'CALL',
      quickConnectButtonColdTransfer: 'Cold Transfer',
      quickConnectButtonHoldAndCall: 'HOLD & CALL',
      quickConnectButtonTransfer: 'TRANSFER',
      quickConnectFallbackMessage: 'Unable to Display Quick Connects List',
      quickConnectsLoading: 'Loading quick connects',
      RECORDING: 'RECORDING',
      resume: 'Resume',
      signingOut: 'Signing Out...',
      signedOut: 'Signed Out',
      signOut: 'Sign Out',
      snackbarCallbackAutoAcceptError:
        'An error occurred when attempting to auto-accept a queued callback. Please manually accept the call in your CCP',
      snackbarAgentOfflineError:
        'An error occurred when setting the agent status to offline. Please ensure there are no in-progress contacts before signing out.',
      snackbarAutoAcceptSuccess:
        'A queued callback was auto-accepted on your behalf',
      snackbarClickToCallError:
        'A click-to-call error occurred, unable to create new contact',
      snackbarClickToCallAddConnectionError:
        'A click-to-call error occurred, unable to create new contact',
      snackbarContactInfoError:
        'An error occurred when attempting to display Contact Info for the currently selected contact. If this behavior continues consistently for future contacts, please contact your administrator.',
      snackbarCopiedToClipboard: 'Copied to clipboard',
      snackbarLineAppearanceError:
        'An error occurred when loading the line appearance settings. If this behavior continues consistently for future contacts, please contact your administrator.',
      snackbarLoginError:
        'An error was encountered while logging in. Please try again. If the issue persists, contact your administrator.',
      snackbarQuickConnectChatNewCallError:
        'An error occurred when dialing and the call could not be completed. If this behavior continues consistently for future contacts, please contact your administrator.',
      snackbarQuickConnectDialError:
        'An error occurred when dialing and the call could not be completed. If this behavior continues consistently for future contacts, please contact your administrator.',
      snackbarQuickConnectError:
        'An error occurred when attempting to display Quick Connects. If this behavior continues consistently for future contacts, please contact your administrator.',
      snackbarQuickConnectExistingContactError:
        'Unable to create a new outbound voice contact because a voice contact is currently in progress. Please select the voice contact tab in the CCP to use quick connects for conference/transfer.',
      snackbarQuickConnectNewPartyError:
        'An error occurred when adding a new party to the call. If this behavior continues consistently for future contacts, please contact your administrator.',
      snackbarQuickConnectColdTransferDisconnectFailure:
        'New call leg was created but we were unable to disconnect you from the call',
      snackbarQuickConnectColdTransferFailure: 'Cold transfer failed',
      snackbarQuickConnectColdTransferSuccess: 'Transfer success',
      start: 'Start',
      stop: 'Stop',
      STOPPED: 'STOPPED',
      tooltipRecordingBlocked:
        'Recording controls have either been explicitly disabled for this contact, or this contact is not configured correctly for recording controls',
      tooltipRecordingStopped:
        'Once recording is stopped, it cannot be resumed for the current contact. If you intend to resume recording after a temporary pause, use the "PAUSE" function instead',
      unableToDisplayContactInfo:
        'Unable to Display Contact Information for Selected Contact',
      callLog: {
        errorBoundaryMessage: 'An error occurred when displaying your call log',
        noCallLogEntries: 'There are no call log entries to display',
        pageTitle: "Today's Calls",
        tabLabel: 'Call Log',
        tooltipCopyButton: 'Copy phone number',
        toolTipClickToCall: 'Click to call',
        toolTipClickToAddConnection: 'Hold and add new party to call',
      },
      metrics: {
        errorBoundaryMessage: 'An error occurred when retrieving metrics',
        lastUpdated: 'Last updated:',
        loadingMetrics: 'Loading metrics',
        pageTitle: "Today's Metrics",
        refresh: 'Refresh',
        tabLabel: 'User Metrics',
        refreshToolTip:
          'The last updated timestamp indicates when updated metrics were requested. The metrics displayed are near realtime, so they may still run a few minutes behind',
        snackbarErrorGettingMetrics:
          'An error occurred when retrieving metrics, please try again later.',
        labels: {
          ABANDONMENT_RATE: 'Abandonment Rate',
          AGENT_ADHERENT_TIME: 'Adherent Time',
          AGENT_ANSWER_RATE: 'Answer Rate',
          AGENT_NON_ADHERENT_TIME: 'Non-Adherent Time',
          AGENT_NON_RESPONSE: 'Non-Response',
          AGENT_NON_RESPONSE_WITHOUT_CUSTOMER_ABANDONS:
            'Non-Response Without Customer Abandons',
          AGENT_OCCUPANCY: 'Occupancy',
          AGENT_SCHEDULE_ADHERENCE: 'Schedule Adherence',
          AGENT_SCHEDULED_TIME: 'Scheduled Time',
          AVG_ABANDON_TIME: 'Avg Abandon Time',
          AVG_ACTIVE_TIME: 'Avg Active Time',
          AVG_AFTER_CONTACT_WORK_TIME: 'Avg After Contact Work Time',
          AVG_AGENT_CONNECTING_TIME: 'Avg Connecting Time',
          AVG_AGENT_PAUSE_TIME: 'Avg Pause Time',
          AVG_CONTACT_DURATION: 'Avg Contact Duration',
          AVG_CONVERSATION_DURATION: 'Avg Conversation Duration',
          AVG_GREETING_TIME_AGENT: 'Avg Greeting Time',
          AVG_HANDLE_TIME: 'Avg Handle Time',
          AVG_HOLD_TIME: 'Avg Hold Time',
          AVG_HOLD_TIME_ALL_CONTACTS: 'Avg Hold Time All Contacts',
          AVG_HOLDS: 'Avg Holds',
          AVG_INTERACTION_AND_HOLD_TIME: 'Avg Interaction + Hold Time',
          AVG_INTERACTION_TIME: 'Avg Interaction Time',
          AVG_INTERRUPTIONS_AGENT: 'Avg Representative Interruptions',
          AVG_INTERRUPTION_TIME_AGENT: 'Avg Representative Interruption Time',
          AVG_NON_TALK_TIME: 'Avg Non-Talk Time',
          AVG_TALK_TIME: 'Avg Talk Time',
          AVG_TALK_TIME_AGENT: 'Avg Representative Talk Time',
          AVG_TALK_TIME_CUSTOMER: 'Avg Caller Talk Time',
          CONTACTS_ABANDONED: 'Contacts Abandoned',
          CONTACTS_HANDLED: 'Contacts Handled',
          CONTACTS_HOLD_ABANDONS: 'Contacts On-Hold Disconnect',
          CONTACTS_ON_HOLD_AGENT_DISCONNECT:
            'Contacts On-Hold Agent Disconnect',
          CONTACTS_ON_HOLD_CUSTOMER_DISCONNECT:
            'Contacts On-Hold Customer Disconnect',
          CONTACTS_PUT_ON_HOLD: 'Contacts Put On Hold',
          CONTACTS_TRANSFERRED_OUT_EXTERNAL:
            'Contacts Transferred Out External',
          CONTACTS_TRANSFERRED_OUT_INTERNAL:
            'Contacts Transferred Out Internal',
          CONTACTS_TRANSFERRED_OUT: 'Contacts Transferred Out',
          PERCENT_NON_TALK_TIME: 'Non-Talk Time Percent',
          PERCENT_TALK_TIME: 'Talk Time Percent',
          PERCENT_TALK_TIME_AGENT: 'Talk Time Agent Percent',
          PERCENT_TALK_TIME_CUSTOMER: 'Talk Time Customer Percent',
          SUM_AFTER_CONTACT_WORK_TIME: 'Total After Contact Work Time',
          SUM_CONNECTING_TIME_AGENT: 'Total Agent API Connecting Time',
          SUM_CONTACT_TIME_AGENT: 'Total On-Contact Time',
          SUM_ERROR_STATUS_TIME_AGENT: 'Total Error Status Time',
          SUM_HANDLE_TIME: 'Total Handle Time',
          SUM_HOLD_TIME: 'Total Hold Time',
          SUM_IDLE_TIME_AGENT: 'Total Representative Idle Time',
          SUM_INTERACTION_AND_HOLD_TIME: 'Total Interaction + Hold Time',
          SUM_INTERACTION_TIME: 'Total Interaction Time',
          SUM_NON_PRODUCTIVE_TIME_AGENT: 'Total Non-Productive Time',
          SUM_ONLINE_TIME_AGENT: 'Total Online Time',
        },
      },
    },
  },
};

i18n
  .use(initReactI18next)
  .init({ resources, lng: 'en', interpolation: { escapeValue: false } });

export default i18n;
