import { BaseSyntheticEvent, useState } from 'react';
import { Collapse, List, ListItemButton, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { QuickConnectListItem } from './QuickConnectListItem';

interface INestedQuickConnectListItemProps {
  name: string;
  quickConnects: connect.Endpoint[];
  setSelectedQc: (id: string) => void;
  selectedQc: string;
}

export const NestedQuickConnectListItem = ({
  name,
  quickConnects,
  setSelectedQc,
  selectedQc,
}: INestedQuickConnectListItemProps) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleClick = (event: BaseSyntheticEvent) => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick} key={name}>
        <ListItemText
          primary={name}
          primaryTypographyProps={{
            noWrap: name.split(' ').length > 1 ? false : true,
            fontWeight: 'bold',
          }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <List component='div' disablePadding>
          {quickConnects.map((item) => (
            <QuickConnectListItem
              endpoint={item}
              key={item.endpointId}
              setSelectedQc={setSelectedQc}
              selectedQc={selectedQc}
            />
          ))}
        </List>
      </Collapse>
    </>
  );
};
