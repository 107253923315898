import React, { useContext, useEffect, useState } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { AuthTokens, AuthUser, fetchAuthSession } from 'aws-amplify/auth';
import { logger } from '../utilities/logger';
import { Tokens } from '../types';

interface IAuthContext {
  authStatus: 'configuring' | 'authenticated' | 'unauthenticated';
  logout: boolean;
  user: AuthUser;
  tokens: Tokens;
  rawIdToken: AuthTokens['idToken'] | null;
  refreshTokens: () => Promise<Tokens>;
  setLogout: (state: boolean) => void;
}

const AuthContext = React.createContext<IAuthContext | null>(null);

export function useAuthContext() {
  const state = useContext(AuthContext);

  if (!state) {
    throw new Error('useAuthorizerContext must be used within AuthProvider');
  }

  return state;
}

export function AuthProvider({ children }: React.PropsWithChildren) {
  const log = logger();
  const [tokens, setTokens] = useState<Tokens>({
    idToken: null,
    accessToken: null,
  });
  const [rawIdToken, setRawIdToken] = useState<AuthTokens['idToken'] | null>(
    null
  );
  const [logout, setLogout] = useState<boolean>(false);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { user } = useAuthenticator((context) => [context.user]);

  const getSessionTokens = async () => {
    const { tokens } = await fetchAuthSession();
    if (tokens && tokens.idToken && tokens.accessToken) {
      const { idToken, accessToken } = tokens;
      return {
        idToken: idToken.toString(),
        accessToken: accessToken.toString(),
      };
    } else {
      return {
        idToken: null,
        accessToken: null,
      };
    }
  };

  const getRawIdToken = async () => {
    const { tokens } = await fetchAuthSession();
    if (tokens && tokens.idToken) {
      return tokens.idToken;
    }
    return null;
  };

  useEffect(() => {
    if (authStatus === 'authenticated') {
      getSessionTokens().then((tokenObj) => {
        setTokens({ ...tokenObj });
      });
      getRawIdToken().then((idToken) => {
        setRawIdToken(idToken);
      });
    }
  }, [authStatus]);

  const refreshTokens = async () => {
    const tokenObj = await getSessionTokens();
    setTokens({ ...tokenObj });
    return tokenObj;
  };

  const providerValue = {
    authStatus,
    logout,
    user,
    tokens,
    rawIdToken,
    refreshTokens,
    setLogout,
  };

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
}
