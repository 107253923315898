import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useContactContext } from '../providers/ContactProvider';
import { useAppStateContext } from '../providers/AppStateProvider';
import { useQuickConnectionContext } from '../providers/QuickConnectProvider';
import { useTranslation } from 'react-i18next';

interface IQuickConnectActionButtonProps {
  selectedQc: string;
  setSelectedQc: (state: string) => void;
}

export const QuickConnectActionButton = ({
  selectedQc,
  setSelectedQc,
}: IQuickConnectActionButtonProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { agent, selectedContact } = useContactContext();
  const { setSnackbar } = useAppStateContext();
  const { agentEndpoints } = useQuickConnectionContext();
  const { t } = useTranslation();

  const getDisabled = () => {
    if (!selectedQc) {
      return true;
    } else if (
      selectedContact?.getState().type === connect.ContactStateType.ENDED &&
      selectedContact.getType() === connect.ContactType.CHAT &&
      agent
        ?.getContacts()
        .find((contact) => contact.getType() === connect.ContactType.VOICE)
    ) {
      // AGENT'S SELECTED CONTACT IS A CHAT CONTACT IN AFTER CALL WORK (ACW) BUT THEY ALREADY HAVE AN ACTIVE VOICE CONTACT SO WE DISABLE THE BUTTON
      return true;
    } else if (
      selectedContact?.getState().type === connect.ContactStateType.ENDED &&
      selectedContact.getType() === connect.ContactType.VOICE
    ) {
      // AGENT'S SELECTED CONTACT IS A VOICE CONTACT IN AFTER CALL WORK (ACW) SO WE CAN'T CREATE OR TRANSFER A CALL FOR THEM
      return true;
    } else {
      return false;
    }
  };

  const newCall = (endpoint: connect.Endpoint, errorMessage: string) => {
    setLoading(true);
    if (agent) {
      return agent.connect(endpoint, {
        success: () => {
          clearSelectedQc();
          setLoading(false);
        },
        failure: () => {
          setLoading(false);
          setSnackbar({
            message: errorMessage,
            timeout: 6000,
            open: true,
            severity: 'warning',
          });
        },
      });
    }
    return;
  };

  const newConnection = (
    endpoint: connect.Endpoint,
    errorMessage: string,
    selectedContact: connect.Contact
  ) => {
    setLoading(true);
    selectedContact.addConnection(endpoint, {
      success: () => {
        setLoading(false);
        clearSelectedQc();
      },
      failure: () => {
        setLoading(false);
        setSnackbar({
          message: errorMessage,
          timeout: 6000,
          open: true,
          severity: 'warning',
        });
      },
    });
  };

  const clearSelectedQc = () => setSelectedQc('');

  const endpoint =
    agentEndpoints &&
    agentEndpoints.endpoints.find(
      (endpoint) => endpoint.endpointId === selectedQc
    );

  const clickHandler = () => {
    if (agent && endpoint) {
      const contacts = agent.getContacts();
      if (!selectedContact && !contacts.length) {
        // THERE ARE NO ACTIVE CONTACTS, CREATE OUTBOUND VOICE CONTACT
        newCall(endpoint, t('snackbarQuickConnectDialError'));
      } else if (selectedContact) {
        // THE AGENT IS ON A CONTACT
        const contactType = selectedContact.getType();
        if (contactType === connect.ContactType.VOICE) {
          // AGENT IS ON A VOICE CONTACT
          newConnection(
            endpoint,
            t('snackbarQuickConnectNewPartyError'),
            selectedContact
          );
        } else if (contactType === connect.ContactType.CHAT) {
          // AGENT IS ON A CHAT CONTACT
          if (
            selectedContact.getState().type === connect.ContactStateType.ENDED
          ) {
            // CHAT CONTACT IS IN AFTER CALL WORK (ACW), ACTION WILL CREATE A NEW VOICE CONTACT IF NOT VOICE CONTACT IS ACTIVE
            const contacts = agent.getContacts();
            const activeVoiceContact = contacts.find(
              (contact) => contact.getType() === connect.ContactType.VOICE
            );
            if (!activeVoiceContact) {
              //NO VOICE CONTACT IS ACTIVE SO WE CREATE A NEW VOICE CONTACT AS A COURTESY (AGENT SHOULD REALLY CLICK ON VOICE TAB IN CCP FIRST BECAUSE THE CHAT IS CURRENTLY SELECTED)
              newCall(endpoint, t('snackbarQuickConnectChatNewCallError'));
            } else {
              // AGENT HAS AN ACTIVE VOICE CONTACT
              setSnackbar({
                message: t('snackbarQuickConnectNewPartyError'),
                timeout: 6000,
                open: true,
                severity: 'warning',
              });
            }
          } else {
            // CHAT CONTACT IS IN PROGRESS
            selectedContact.addConnection(endpoint, {
              success: () => {
                setLoading(false);
                clearSelectedQc();
              },
              failure: () => {
                setLoading(false);
                setSnackbar({
                  message: t('snackbarQuickConnectNewPartyError'),
                  timeout: 6000,
                  open: true,
                  severity: 'warning',
                });
              },
            });
          }
        }
      }
    }
  };

  const buttonLabel = () => {
    if (
      !selectedContact ||
      selectedContact.getType() === connect.ContactType.VOICE
    ) {
      if (!selectedContact) {
        // No active selected contact, always use "CALL" label
        return t('quickConnectButtonCall');
      } else {
        // The selected contact is an active voice contact
        if (
          selectedContact.getState().type === connect.ContactStateType.ENDED
        ) {
          // The selected contact is in ACW and button will be disabled
          return t('quickConnectButtonCall');
        } else if (
          selectedContact.getState().type === connect.ContactStateType.CONNECTED
        ) {
          // The selected contact is actively connected
          return t('quickConnectButtonHoldAndCall');
        } else {
          return t('quickConnectButtonCall');
        }
      }
    } else if (selectedContact.getType() === connect.ContactType.CHAT) {
      // Active selected contact is a chat
      if (selectedContact.getState().type === connect.ContactStateType.ENDED) {
        // Active selected chat contact is in after call work and thus QCs will not apply to chat contact
        return t('quickConnectButtonCall');
      } else {
        // Active selected chat is in progress, so QCs will apply to chat contact
        return t('quickConnectButtonTransfer');
      }
    } else {
      return t('quickConnectButtonTransfer');
    }
  };

  return (
    <LoadingButton
      disabled={getDisabled()}
      size='large'
      variant='contained'
      // sx={{ marginLeft: '1rem' }}
      onClick={clickHandler}
      loading={loading}
    >
      {buttonLabel()}
    </LoadingButton>
  );
};
