import { useCallback, useState } from 'react';
import { Box, Unstable_Grid2 as Grid, Paper } from '@mui/material';
import { CCPError, ConnectCCP } from '../components/ConnectCCP';
import { useContactContext } from '../providers/ContactProvider';
import { Tab, Tabs } from '@mui/material';
import { TabPanel } from '../components/TabPanel';
import { ContactInfo, ContactInfoError } from '../components/ContactInfo';
import {
  RecordingControls,
  RecordingControlsError,
} from '../components/RecordingControls';
import { ErrorBoundary } from 'react-error-boundary';
import { useAppStateContext } from '../providers/AppStateProvider';
import { logger } from '../utilities/logger';
import { QuickConnectsError } from '../components/QuickConnects';
import { QuickConnects } from '../components/QuickConnects';
import { ClidSettings } from '../components/ClidSettings';
import { useTranslation } from 'react-i18next';
import { ProtectedComponent } from '../components/ProtectedComponent';
import { getConfig } from '../config';
import { AgentMetrics, AgentMetricsError } from '../components/AgentMetrics';
import { QuickConnectProvider } from '../providers/QuickConnectProvider';
import { CallLog, CallLogError } from '../components/CallLog';

export const Dashboard = () => {
  const log = logger();
  const CCP = useCallback(() => <ConnectCCP />, []);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const config = getConfig();

  const recordOnDemandSgId = config.RECORD_ON_DEMAND_SG_ID;
  const userSecurityGroupAttributeName = config.USER_SG_ATTRIBUTE_NAME;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  // Component Contexts
  const { agentExternalConfig, selectedContact } = useContactContext();
  const { setSnackbar } = useAppStateContext();
  const { t } = useTranslation();

  const contactInfoErrorHandler = (error: Error, info: React.ErrorInfo) => {
    log.error('Error boundary hit for contact info', error, info);
    setSnackbar({
      message: t('snackbarContactInfoError'),
      timeout: 10000,
      open: true,
      severity: 'warning',
    });
  };

  const quickConnectErrorHandler = (error: Error, info: React.ErrorInfo) => {
    log.error('Error boundary hit for quick connects', error, info);
    setSnackbar({
      message: t('snackbarQuickConnectError'),
      timeout: 10000,
      open: true,
      severity: 'warning',
    });
  };

  const ccpErrorHandler = (error: Error, info: React.ErrorInfo) => {
    log.error('CCP Error', error);
  };

  const ProtectedRecordingControlsError = () => {
    return (
      <ProtectedComponent
        requiredSgId={recordOnDemandSgId}
        userSgAttributeName={userSecurityGroupAttributeName}
      >
        {' '}
        <RecordingControlsError />
      </ProtectedComponent>
    );
  };

  return (
    <Grid
      container
      className='dashboard-grid-container'
      direction='row'
      padding={1}
      flexWrap={'nowrap'}
      height={'100%'}
      width={'100%'}
    >
      <Grid minWidth={400}>
        <Grid
          container
          className='ccp-grid-container'
          direction='column'
          height='100%'
          flexGrow={1}
          minWidth={400}
          flexWrap={'nowrap'}
          // Added to make CCP container scrollable to avoid overflow on entire document
          overflow={'auto'}
        >
          <ErrorBoundary fallbackRender={CCPError} onError={ccpErrorHandler}>
            <CCP />
          </ErrorBoundary>
          <ErrorBoundary
            fallbackRender={ProtectedRecordingControlsError}
            resetKeys={[selectedContact]}
          >
            <ProtectedComponent
              requiredSgId={recordOnDemandSgId}
              userSgAttributeName={userSecurityGroupAttributeName}
            >
              <RecordingControls />
            </ProtectedComponent>
          </ErrorBoundary>
          {agentExternalConfig && agentExternalConfig.hasDidAssigned && (
            <ClidSettings />
          )}
        </Grid>
      </Grid>
      <Grid width='100%' height='100%' paddingLeft={1}>
        <Grid
          container
          className='features-grid-container'
          direction='column'
          height='100%'
          width='100%'
          flexWrap={'nowrap'}
          overflow='auto'
          component={Paper}
        >
          <Grid height='100%' width='100%'>
            <Grid
              container
              direction='column'
              padding={'12px'}
              className='tab-container'
              height='100%'
              width='100%'
            >
              <Grid height='100%' width='100%'>
                <Grid container direction='column'>
                  <Grid
                    sx={{ borderBottom: 1, borderColor: 'divider' }}
                    width='100%'
                    height='100%'
                  >
                    <Tabs
                      value={tabIndex}
                      onChange={handleTabChange}
                      aria-label='dashboard-feature-tabs'
                      variant='scrollable'
                    >
                      <Tab
                        label={t('contactInfo')}
                        id='dashboard-tab-0'
                        aria-controls='contact-info-tab-0'
                      />
                      <Tab
                        label={t('quickConnects')}
                        id='dashboard-tab-1'
                        aria-controls='quick-connects-tab-1'
                      />
                      <Tab
                        label={t('metrics.tabLabel')}
                        id='dashboard-tab-2'
                        aria-controls='user-metrics-tab-2'
                      />
                      <Tab
                        label={t('callLog.tabLabel')}
                        id='dashboard-tab-3'
                        aria-controls='call-log-tab-3'
                      />
                    </Tabs>
                  </Grid>
                </Grid>
                <Grid container direction='column' flex={1}>
                  <TabPanel index={0} value={tabIndex}>
                    <ErrorBoundary
                      fallbackRender={ContactInfoError}
                      resetKeys={[selectedContact]}
                      onError={contactInfoErrorHandler}
                    >
                      <ContactInfo selectedContact={selectedContact} />
                    </ErrorBoundary>
                  </TabPanel>
                  <TabPanel index={1} value={tabIndex}>
                    <ErrorBoundary
                      fallbackRender={QuickConnectsError}
                      resetKeys={[selectedContact]}
                      onError={quickConnectErrorHandler}
                    >
                      <QuickConnectProvider>
                        <QuickConnects selectedContact={selectedContact} />
                      </QuickConnectProvider>
                    </ErrorBoundary>
                  </TabPanel>
                  <TabPanel index={2} value={tabIndex}>
                    <ErrorBoundary
                      fallbackRender={AgentMetricsError}
                      resetKeys={[selectedContact]}
                    >
                      <AgentMetrics />
                    </ErrorBoundary>
                  </TabPanel>
                  <TabPanel index={3} value={tabIndex}>
                    <ErrorBoundary
                      fallbackRender={CallLogError}
                      resetKeys={[selectedContact]}
                    >
                      <CallLog />
                    </ErrorBoundary>
                  </TabPanel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
