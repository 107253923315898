import { Container, Grid } from '@mui/material';
import { useContactContext } from '../providers/ContactProvider';
import { QuickConnectActionButton } from './QuickConnectActionButton';
import { QuickConnectColdTransferButton } from './QuickConnectColdTransferButton';

interface IQuickConnectControlsProps {
  selectedQc: string;
  setSelectedQc: (state: string) => void;
}
export const QuickConnectControls = ({
  selectedQc,
  setSelectedQc,
}: IQuickConnectControlsProps) => {
  const { selectedContact } = useContactContext();

  const displayExtraCallButtons = () => {
    return (
      selectedContact &&
      selectedContact.getType() === connect.ContactType.VOICE &&
      selectedContact.getState().type === connect.ContactStateType.CONNECTED
    );
  };

  return (
    <Container
      sx={{
        height: '5rem',
        marginTop: '1rem',
      }}
    >
      <Grid
        container
        height='100%'
        flexDirection='row'
        justifyContent='end'
        alignItems='center'
        wrap='nowrap'
        spacing={2}
      >
        {displayExtraCallButtons() && (
          <Grid item>
            <QuickConnectColdTransferButton
              selectedQc={selectedQc}
              setSelectedQc={setSelectedQc}
            />
          </Grid>
        )}
        <Grid item>
          <QuickConnectActionButton
            selectedQc={selectedQc}
            setSelectedQc={setSelectedQc}
          />
        </Grid>
      </Grid>
    </Container>
  );
};
