import React from 'react';
import { FC, useCallback, useMemo } from 'react';
import { Logout } from '../pages/Logout';
import { AppLayout } from './AppLayout';
import { Navigate } from 'react-router-dom';
import { useAuthContext } from '../providers/AuthProvider';

const withLayout = (Component: FC) => (props: any) => {
  const Comp = useCallback(() => <Component {...props} />, [props]);
  return (
    <AppLayout>
      <Comp />
    </AppLayout>
  );
};

export const ProtectedRoute = ({ children }: React.PropsWithChildren) => {
  const { authStatus, logout } = useAuthContext();
  const LogoutWithLayout = useMemo(() => withLayout(Logout), []);

  if (authStatus === 'unauthenticated' && logout) {
    return <Navigate to='/logout' />;
    // return <LogoutWithLayout />;
  } else if (
    (authStatus === 'unauthenticated' && !logout) ||
    authStatus === 'configuring'
  ) {
    return <Navigate to='/login' />;
  }

  return children;
};
